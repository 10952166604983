import React from "react";

const MentionsLegales = () => {
  return (
    <div className="container mx-auto p-4 sm:p-6 lg:p-8 max-w-3xl">
      <div className="bg-white shadow-lg rounded-2xl p-6">
        <h1 className="text-2xl font-bold mb-6 text-center">
          Mentions Légales
        </h1>

        <p className="mb-4">
          <strong>Nom du site :</strong> Avis Réel
        </p>

        <p className="mb-4">
          <strong>Adresse du site :</strong>{" "}
          <a
            href="https://www.avisreel.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline hover:text-blue-700"
          >
            https://www.avisreel.com
          </a>
        </p>

        <p className="mb-4">
          <strong>Propriétaire :</strong> Triplon Alexandre -{" "}
          <a
            href="tel:0624247438"
            className="text-blue-500 underline hover:text-blue-700"
          >
            06 24 24 74 38
          </a>{" "}
          -{" "}
          <a
            href="mailto:alexavisreel@gmail.com"
            className="text-blue-500 underline hover:text-blue-700"
          >
            alexavisreel@gmail.com
          </a>
          .
        </p>

        <p className="mb-4">
          <strong>SIREN :</strong> En cours
        </p>

        <p className="mb-4">
          <strong>Responsable de la publication :</strong> Triplon Alexandre -{" "}
          <a
            href="mailto:alexavisreel@gmail.com"
            className="text-blue-500 underline hover:text-blue-700"
          >
            alexavisreel@gmail.com
          </a>
          .
        </p>

        <p className="mb-4">
          <strong>Hébergement :</strong> Le site est hébergé par Firebase
          (Google LLC), situé au 1600 Amphitheatre Parkway, Mountain View, CA
          94043, États-Unis.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-4">
          Conditions d’utilisation
        </h2>
        <p className="mb-4">
          L’utilisation du présent site implique l’acceptation pleine et entière
          des conditions générales d’utilisation décrites ci-après. Ces
          conditions d’utilisation sont susceptibles d’être modifiées ou
          complétées à tout moment.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-4">Informations</h2>
        <p className="mb-4">
          Les informations et documents du site sont présentés à titre
          indicatif, sans caractère exhaustif, et ne peuvent engager la
          responsabilité du propriétaire du site.
        </p>
        <p className="mb-4">
          Le propriétaire ne peut être tenu responsable des dommages directs ou
          indirects liés à l’accès ou à l’utilisation du site.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-4">Interactivité</h2>
        <p className="mb-4">
          Les utilisateurs peuvent déposer du contenu sur le site (par exemple
          via les commentaires). Ce contenu reste sous la responsabilité des
          auteurs, qui en assument l’entière responsabilité juridique. Le
          propriétaire se réserve le droit de retirer tout contenu jugé
          inapproprié sans préavis.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-4">
          Propriété intellectuelle
        </h2>
        <p className="mb-4">
          Sauf mention contraire, tous les éléments du site (textes, images,
          graphismes, logo, icônes, sons, logiciels, etc.) sont protégés par les
          droits d’auteur et restent la propriété exclusive de leurs auteurs.
        </p>
        <p className="mb-4">
          Toute reproduction ou utilisation non autorisée est interdite.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-4">Liens</h2>
        <p className="mb-4">
          <strong>Liens sortants :</strong> Le propriétaire du site décline
          toute responsabilité concernant les ressources tierces accessibles via
          des liens hypertextes.
        </p>
        <p className="mb-4">
          <strong>Liens entrants :</strong> Les liens hypertextes vers ce site
          sont autorisés sous certaines conditions : ouvrir une nouvelle
          fenêtre, éviter toute confusion ou présentation tendancieuse.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-4">Confidentialité</h2>
        <p className="mb-4">
          Tout utilisateur dispose d’un droit d’accès, de rectification et
          d’opposition aux données personnelles le concernant. Les données
          collectées (formulaires d’avis, réservations, etc.) sont sécurisées et
          utilisées uniquement dans le cadre des fonctionnalités du site. Pour
          exercer vos droits, contactez-nous à :{" "}
          <a
            href="mailto:alexavisreel@gmail.com"
            className="text-blue-500 underline hover:text-blue-700"
          >
            alexavisreel@gmail.com
          </a>
          .
        </p>

        <p className="mb-4">
          <strong>Cookies :</strong> Le site utilise des cookies pour améliorer
          l’expérience utilisateur et des services analytiques. Vous pouvez
          configurer vos préférences dans votre navigateur.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-4">Responsabilité</h2>
        <p className="mb-4">
          Avis Réel décline toute responsabilité en cas d’inexactitudes ou
          d’omissions dans les informations fournies sur le site.
        </p>

        <p className="text-center mt-6">
          Pour toute question, veuillez nous contacter à :{" "}
          <a
            href="mailto:alexavisreel@gmail.com"
            className="text-blue-500 underline hover:text-blue-700"
          >
            alexavisreel@gmail.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default MentionsLegales;
