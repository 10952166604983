import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import MainContent from "./components/MainContent/MainContent";
import Footer from "./components/Footer/Footer";
import Contact from "./components/Contact/Contact";
import RegisterEstablishment from "./components/RegisterEstablishment/RegisterEstablishment";
import EtablissementPage from "./pages/EtablissementPage";
import ConfirmationPage from "./pages/ConfirmationPage";
import EtablissementDetailPage from "./pages/EtablissementDetailPage";
import GeoValidationPage from "./pages/GeoValidationPage";
import SearchResultsPage from "./pages/SearchResultsPage";
import Leaderboard from "./pages/Leaderboard";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute"; // Importer PrivateRoute
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import AdminDashboard from "./pages/AdminDashboard";
import AutoLogout from "./components/AutoLogout/AutoLogout";
import MentionsLegales from "./components/Footer/MentionsLegales";
import HowItWorks from "./pages/HowItWorks";

function App() {
  return (
    <Router>
      <AutoLogout />
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="/login" element={<Login />} />

          {/* Route protégée pour les utilisateurs authentifiés */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />

          {/* Route protégée pour les administrateurs uniquement */}
          <Route
            path="/adminDashboard"
            element={
              <PrivateRoute requiredRole="admin">
                <AdminDashboard />
              </PrivateRoute>
            }
          />

          <Route path="/contact" element={<Contact />} />
          <Route
            path="/registerEstablishment"
            element={<RegisterEstablishment />}
          />
          <Route path="/etablissement/:code" element={<EtablissementPage />} />
          <Route
            path="/etablissement/details/:id"
            element={<EtablissementDetailPage />}
          />
          <Route path="/confirmation" element={<ConfirmationPage />} />
          <Route path="/geoValidation" element={<GeoValidationPage />} />
          <Route path="/search-results" element={<SearchResultsPage />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/mentions-legales" element={<MentionsLegales />} />
          <Route
            path="/howItWorks"
            element={
              <PrivateRoute>
                <HowItWorks />
              </PrivateRoute>
            }
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
