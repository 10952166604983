import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  handleCodeSearch,
  fetchSearchResults,
} from "../../services/firebaseUtils";
import GoButtonImage from "../../assets/go_button.png";
import GoButtonImageHover from "../../assets/go_button_hover.png";
import {
  searchEtablissementsByNameAndLocation,
  searchEtablissementsByType,
} from "../../services/firebaseServices";
import LatestReviews from "../LatestReviews/LatestReviews";
//import AdSenseAd from "../Ads/AdsenseAd";
//import HorizontalAd from "../Ads/HorizontalAd";

const MainContent = () => {
  const [isGeolocationValidated, setIsGeolocationValidated] = useState(false);
  const [isHoveringLeft, setIsHoveringLeft] = useState(false);
  const [isHoveringRight, setIsHoveringRight] = useState(false);
  const [code, setCode] = useState("");
  const [etablissement, setEtablissement] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQueryTop, setSearchQueryTop] = useState("");
  const [searchResultsTop, setSearchResultsTop] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // Nombre de résultats visibles dans la liste
  const [searchQueryAvis, setSearchQueryAvis] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchClicked, setSearchClicked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(
      window.location.search || window.location.hash.split("?")[1]
    );
    const scannedCode = params.get("code");

    if (scannedCode) setCode(scannedCode);
  }, []);

  const handleSearchSubmit = async () => {
    if (searchQueryTop.trim()) {
      try {
        navigate(
          `/search-results?query=${encodeURIComponent(searchQueryTop.trim())}`
        );
      } catch (error) {
        console.error("Erreur lors de la soumission de la recherche :", error);
      }
    }
  };

  const handleTypeSearch = async (type) => {
    try {
      // Recherche des établissements par type
      const results = await searchEtablissementsByType(type);
      navigate(`/search-results?query=${encodeURIComponent(type)}`, {
        state: { results },
      });
    } catch (error) {
      console.error("Erreur lors de la recherche par type :", error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchSubmit();
    }
  };

  const handleTextSearch = async () => {
    setSearchClicked(true);
    setIsLoading(true);
    setSearchResults([]); // Réinitialise les résultats

    try {
      // Recherche des établissements validés uniquement
      const results = await searchEtablissementsByNameAndLocation(
        searchQueryAvis,
        searchLocation
      );

      // Filtre les résultats pour ne garder que ceux dont le champ "valide" est true
      const validResults = results.filter((result) => result.valide === true);

      setSearchResults(validResults);
    } catch (error) {
      console.error("Erreur lors de la recherche :", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSearchResults(searchQueryTop, setSearchResultsTop);
  }, [searchQueryTop]);

  useEffect(() => {
    setVisibleCount(5); // Réinitialise le compteur de résultats visibles lorsqu'une nouvelle recherche est effectuée
  }, [searchQueryTop]);

  return (
    <div className="flex flex-col items-center gap-2 p-2 mx-auto">
      {/* Boutons pour filtres par type */}
      <div className="flex gap-4">
        <button
          onClick={() => handleTypeSearch("Restaurant")}
          className="bg-bleu text-white px-4 py-2 rounded-lg hover:bg-vert-dark transition-all"
        >
          Restaurants
        </button>
        <button
          onClick={() => handleTypeSearch("Hôtel")}
          className="bg-bleu text-white px-4 py-2 rounded-lg hover:bg-vert-dark transition-all"
        >
          Hôtels
        </button>
        <button
          onClick={() => handleTypeSearch("Activités")}
          className="bg-bleu text-white px-4 py-2 rounded-lg hover:bg-vert-dark transition-all"
        >
          Activités
        </button>
      </div>
      {/* Barre de recherche générale avec autocomplétion */}
      <div className="w-full max-w-[1000px] flex flex-col items-center mb-2 px-4 relative">
        <input
          type="text"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
          className="w-full p-4 text-lg rounded-lg border-2 border-bleu focus:outline-none focus:ring-4 focus:ring-vert shadow-lg hover:shadow-xl transition-all duration-200 placeholder-gray-500 hover:border-vert"
          placeholder="Recherchez un établissement, un lieu..."
          value={searchQueryTop}
          onChange={(e) => setSearchQueryTop(e.target.value)}
          onKeyDown={handleKeyPress} // Ecoute la touche "Entrée"
        />
        <button
          onClick={handleSearchSubmit}
          className="bg-vert text-white px-4 py-2 rounded-lg mt-2 hover:bg-vert-dark"
        >
          Rechercher
        </button>

        {searchResultsTop.length > 0 && (
          <div className="absolute top-full mt-1 w-full bg-white border border-gray-300 rounded-md shadow-md z-10">
            <ul>
              {searchResultsTop.slice(0, visibleCount).map((result) => (
                <li
                  key={result.id}
                  onClick={() => {
                    navigate(`/etablissement/details/${result.id}`);
                    setSearchQueryTop(result.nom);
                    setSearchResultsTop([]);
                  }}
                  className="p-2 cursor-pointer hover:bg-gray-100"
                >
                  <strong>{result.nom}</strong> - {result.ville}
                </li>
              ))}
            </ul>
            {visibleCount < searchResultsTop.length && (
              <button
                onClick={() => setVisibleCount((prev) => prev + 5)}
                className="w-full p-2 text-center bg-vert text-white font-medium hover:bg-vert-dark transition-all"
              >
                Voir plus
              </button>
            )}
          </div>
        )}
      </div>

      {/* Publicité horizontale */}
      {/* <div>
        <HorizontalAd />
      </div> */}

      {/* Conteneur global : inclut les publicités latérales et le contenu principal */}
      <div className="flex justify-center w-full max-w-7xl mx-auto gap-4">
        {/* Colonne gauche pour la publicité */}
        {/* <div className="hidden md:block w-[160px] bg-black flex items-center justify-center">
          <AdSenseAd
            dataAdClient="ca-pub-2121751983934218"
            dataAdSlot="4044809957" // Remplace par ton Ad Slot
            style={{ display: "block", width: "160px", height: "600px" }}
          />
        </div> */}

        {/* Contenu principal */}
        <div className="flex flex-col items-center w-full max-w-2xl">
          <div className="w-[280px] h-[360px] bg-bleu p-8 text-white relative flex flex-col justify-between rounded-xl">
            <h2 className="text-2xl font-lobster text-black font-bold mb-4">
              Donnez votre avis !!
            </h2>
            <label htmlFor="code" className="block font-bold mb-2">
              Entrez votre Code Sticker :
            </label>
            <input
              id="code"
              type="text"
              className="w-full p-2 text-black mb-4"
              placeholder="Votre code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <button
              className="bg-transparent self-center"
              onMouseEnter={() => setIsHoveringLeft(true)}
              onMouseLeave={() => setIsHoveringLeft(false)}
              onClick={() =>
                handleCodeSearch(code, navigate, setEtablissement, setIsLoading)
              } // Vérifier ici
            >
              <img
                src={isHoveringLeft ? GoButtonImageHover : GoButtonImage}
                alt="Go"
                className="w-24"
              />
            </button>

            {isLoading ? (
              <p>Chargement...</p>
            ) : searchClicked && etablissement ? (
              <div className="mt-4">
                <h3 className="text-xl font-bold">{etablissement.nom}</h3>
                <p>{etablissement.ville}</p>
              </div>
            ) : (
              searchClicked &&
              code && (
                <div className="mt-4">
                  <p className="text-red-500">Code incorrect ou non trouvé</p>
                </div>
              )
            )}
          </div>
        </div>
        {/* Colonne droite pour la publicité */}
        {/* <div className="hidden bg-black md:block w-[160px] flex items-center justify-center">
          <AdSenseAd
            dataAdClient="ca-pub-2121751983934218"
            dataAdSlot="4044809957" // Remplace par ton Ad Slot
            style={{ display: "block", width: "160px", height: "600px" }}
          />
        </div> */}
      </div>

      {/* Section des dernières critiques */}
      <LatestReviews />
    </div>
  );
};

export default MainContent;
