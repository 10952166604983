import { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { fetchDynamicDepartmentsAndCities } from "../utils/departmentNames";
import departmentNames from "../utils/departmentNames";

const ITEMS_PER_PAGE = 5;

const ReviewManagement = () => {
  const [reviews, setReviews] = useState([]);
  const [filters, setFilters] = useState({
    departement: "",
    ville: "",
    type: "",
  });
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [departments, setDepartments] = useState([]);
  const [cities, setCities] = useState({});
  const [types, setTypes] = useState(["Restaurant", "Hôtel", "Activités"]);
  const db = getFirestore();

  useEffect(() => {
    const fetchData = async () => {
      const { departments, cities } = await fetchDynamicDepartmentsAndCities();
      setDepartments(departments);
      setCities(cities);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsQuery = collection(db, "avis");
        const querySnapshot = await getDocs(reviewsQuery);

        const reviewsWithEtablissements = await Promise.all(
          querySnapshot.docs.map(async (reviewDoc) => {
            const reviewData = reviewDoc.data();

            if (!reviewData.id_etablissement) {
              return { id: reviewDoc.id, ...reviewData, etablissement: null };
            }

            try {
              const etablissementRef = doc(
                db,
                "etablissements",
                reviewData.id_etablissement
              );
              const etablissementSnap = await getDoc(etablissementRef);

              if (!etablissementSnap.exists()) {
                return { id: reviewDoc.id, ...reviewData, etablissement: null };
              }

              const etablissementData = etablissementSnap.data();
              return {
                id: reviewDoc.id,
                ...reviewData,
                etablissement: etablissementData,
              };
            } catch (etablissementError) {
              return { id: reviewDoc.id, ...reviewData, etablissement: null };
            }
          })
        );
        // Trier les avis par date (la plus récente en premier)
        reviewsWithEtablissements.sort((a, b) => {
          const dateA = a.date.seconds; // Utilisation de la propriété 'seconds' du Timestamp
          const dateB = b.date.seconds; // Utilisation de la propriété 'seconds' du Timestamp
          return dateB - dateA; // Tri décroissant
        });

        setReviews(reviewsWithEtablissements);
      } catch (error) {
        console.error("Erreur lors de la récupération des avis :", error);
      }
    };

    fetchReviews();
  }, [db]);

  const deleteReview = async (reviewId) => {
    try {
      // Récupérer l'avis que l'on veut supprimer
      const reviewRef = doc(db, "avis", reviewId);
      const reviewSnap = await getDoc(reviewRef);

      if (!reviewSnap.exists()) {
        alert("Avis non trouvé !");
        return;
      }

      const reviewData = reviewSnap.data();

      // Si l'avis contient un id_etablissement, on peut procéder à la mise à jour de l'établissement
      if (reviewData.id_etablissement) {
        const etablissementRef = doc(
          db,
          "etablissements",
          reviewData.id_etablissement
        );
        const etablissementSnap = await getDoc(etablissementRef);

        if (etablissementSnap.exists()) {
          const etablissementData = etablissementSnap.data();

          // Mettre à jour la liste des avis de l'établissement
          const updatedAvis = etablissementData.avis.filter(
            (avisId) => avisId !== reviewId
          );

          // Mettre à jour l'établissement avec la nouvelle liste d'avis
          await updateDoc(etablissementRef, {
            avis: updatedAvis,
            nombreAvis: updatedAvis.length,
            moyenneNote: updatedAvis.length
              ? updatedAvis.reduce((acc, avisId) => {
                  const review = reviews.find((rev) => rev.id === avisId);
                  return acc + review.note;
                }, 0) / updatedAvis.length
              : 0,
          });
        }
      }

      // Supprimer l'avis de la collection "avis"
      await deleteDoc(reviewRef);

      // Mettre à jour l'état des avis pour rafraîchir l'affichage
      setReviews((prevReviews) =>
        prevReviews.filter((review) => review.id !== reviewId)
      );
      alert("Avis supprimé avec succès !");
    } catch (error) {
      console.error("Erreur lors de la suppression de l'avis :", error);
      alert("Erreur lors de la suppression de l'avis !");
    }
  };

  useEffect(() => {
    let result = reviews;

    if (filters.departement) {
      result = result.filter(
        (review) =>
          review.etablissement &&
          review.etablissement.codePostal?.startsWith(filters.departement)
      );
    }

    if (filters.ville) {
      result = result.filter(
        (review) =>
          review.etablissement && review.etablissement.ville === filters.ville
      );
    }

    if (filters.type) {
      result = result.filter(
        (review) =>
          review.etablissement && review.etablissement.type === filters.type
      );
    }

    setFilteredReviews(result);
    setCurrentPage(1);
  }, [filters, reviews]);

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = filteredReviews.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredReviews.length / ITEMS_PER_PAGE);

  const changePage = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div>
      <h2 className="text-3xl font-semibold mb-4">Gestion des avis</h2>

      <div className="flex gap-4 mb-4">
        <select
          value={filters.departement}
          onChange={(e) =>
            setFilters({ ...filters, departement: e.target.value })
          }
          className="p-2 border rounded"
        >
          <option value="">Sélectionner un département</option>
          {departments.map((department) => (
            <option key={department} value={department}>
              {departmentNames[department] || department}{" "}
              {/* Conversion du code département en nom */}
            </option>
          ))}
        </select>

        {filters.departement && (
          <select
            value={filters.ville}
            onChange={(e) => setFilters({ ...filters, ville: e.target.value })}
            className="p-2 border rounded"
          >
            <option value="">Sélectionner une ville</option>
            {cities[filters.departement]?.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
        )}

        <select
          value={filters.type}
          onChange={(e) => setFilters({ ...filters, type: e.target.value })}
          className="p-2 border rounded"
        >
          <option value="">Sélectionner un type</option>
          {types.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>
      <div>
        {currentItems.map((review) => (
          <div key={review.id} className="border mb-4 p-4 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold">{review.titre}</h3>
            {review.etablissement && (
              <>
                <p>
                  <strong>Établissement :</strong> {review.etablissement.nom}
                </p>
                <p>
                  <strong>Adresse :</strong> {review.etablissement.adresse},{" "}
                  {review.etablissement.ville} (
                  {review.etablissement.codePostal})
                </p>
                <p>
                  <strong>Type :</strong> {review.etablissement.type}
                </p>
              </>
            )}
            <p>
              <strong>Note :</strong> {review.note}/5
            </p>
            <p>
              <strong>Auteur :</strong> {review.auteur}{" "}
              {/* Affichage du nom de l'auteur */}
            </p>
            <p>
              <strong>Commentaire :</strong> {review.commentaire}
            </p>
            <p>
              <strong>Date :</strong>{" "}
              {new Date(review.date.seconds * 1000).toLocaleString()}{" "}
              {/* Affichage de la date */}
            </p>

            {/* Bouton de suppression */}
            <button
              onClick={() => deleteReview(review.id, review.etablissement.id)}
              className="bg-red-500 text-white px-4 py-2 rounded mt-2"
            >
              Supprimer
            </button>
          </div>
        ))}
      </div>
      {/* Pagination */}
      <div className="flex justify-center gap-4 mt-4">
        <button
          onClick={() => changePage(currentPage - 1)}
          disabled={currentPage === 1}
          className="p-2 bg-blue-500 text-white rounded"
        >
          Précédent
        </button>
        <span>
          Page {currentPage} / {totalPages}
        </span>
        <button
          onClick={() => changePage(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="p-2 bg-blue-500 text-white rounded"
        >
          Suivant
        </button>
      </div>
    </div>
  );
};

export default ReviewManagement;
