import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { searchEtablissementsInstant } from "./firebaseServices"; // Vérifiez le chemin

export const handleCodeSearch = async (
  code,
  navigate,
  setEtablissement,
  setIsLoading
) => {
  setIsLoading(true);

  try {
    // Vérifie si le code est valide et non utilisé
    const codeRef = doc(db, "codes_uniq", code);
    const docSnap = await getDoc(codeRef);

    if (!docSnap.exists()) {
      alert("Code non trouvé ou invalide.");
      return;
    }

    const codeData = docSnap.data();

    if (codeData.utilise) {
      alert("Ce code a déjà été utilisé.");
      return;
    }

    // Récupère l'établissement lié au code
    const etablissementRef = doc(
      db,
      "etablissements",
      codeData.etablissement_id
    );
    const etablissementSnap = await getDoc(etablissementRef);

    if (etablissementSnap.exists()) {
      const etablissement = etablissementSnap.data();
      setEtablissement(etablissement);

      // Marque le code comme utilisé
      await updateDoc(codeRef, { utilise: true });

      // Redirige vers la page de l'établissement
      navigate(`/etablissement/${etablissement.code_uniq_une_fois[0]}`);
    } else {
      alert("Établissement non trouvé pour ce code.");
    }
  } catch (error) {
    console.error("Erreur lors de la recherche : ", error);
    alert("Erreur lors de la recherche du code.");
  } finally {
    setIsLoading(false);
  }
};

export const fetchSearchResults = async (
  searchQuery,
  setSearchResults,
  filters = {}
) => {
  if (!searchQuery.trim()) {
    setSearchResults([]);
    return;
  }

  try {
    // Recherche initiale avec la query
    const results = await searchEtablissementsInstant(searchQuery);

    // Filtrage des résultats valides
    let filteredResults = results.filter((result) => result.valide === true);

    // Application des filtres supplémentaires
    if (filters.department) {
      filteredResults = filteredResults.filter((result) =>
        result.codePostal.startsWith(filters.department)
      );
    }

    if (filters.city) {
      filteredResults = filteredResults.filter(
        (result) => result.ville.toLowerCase() === filters.city.toLowerCase()
      );
    }

    if (filters.type) {
      filteredResults = filteredResults.filter(
        (result) => result.type.toLowerCase() === filters.type.toLowerCase()
      );
    }

    // Calcul des statistiques (moyenneNote et nombreAvis) uniquement si nécessaire
    const resultsWithStats = filteredResults.map((etablissement) => ({
      ...etablissement,
      moyenneNote: etablissement.moyenneNote || null,
      nombreAvis: etablissement.nombreAvis || 0,
    }));

    // Mise à jour des résultats
    setSearchResults(resultsWithStats);
  } catch (error) {
    console.error("Erreur lors de la recherche :", error);
  }
};
