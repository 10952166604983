export const normalizeString = (str) => {
  // Vérifie si la valeur est une chaîne valide
  if (typeof str !== "string") {
    return ""; // Retourne une chaîne vide si ce n'est pas une chaîne
  }

  return str
    .toLowerCase() // Convertit en minuscule
    .normalize("NFD") // Normalise en supprimant les accents
    .replace(/[\u0300-\u036f]/g, ""); // Supprime les diacritiques (accents)
};
