import { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  getDoc,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  GeoPoint,
} from "firebase/firestore";
import UsersList from "./UsersList";
import EstablishmentValidationList from "./EstablishmentValidationList";
import ReviewManagement from "./ReviewManagement";
import CouponsManagement from "./Coupons";

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState("validation");
  const [establishmentsToValidate, setEstablishmentsToValidate] = useState([]);
  const [allEstablishments, setAllEstablishments] = useState([]);
  const [duplicateEstablishments, setDuplicateEstablishments] = useState([]);
  const [users, setUsers] = useState([]);
  const [showDuplicatesOnly, setShowDuplicatesOnly] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState(null); // Id de l'établissement à supprimer
  const [isConfirmDeleteVisible, setIsConfirmDeleteVisible] = useState(false); // Affichage de la fenêtre de confirmation
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Nombre d'éléments par page
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredEstablishments, setFilteredEstablishments] = useState([]);

  const db = getFirestore();

  // Fetch établissements à valider
  useEffect(() => {
    const fetchEstablishmentsToValidate = async () => {
      const q = query(
        collection(db, "etablissements"),
        where("valide", "==", false)
      );
      const querySnapshot = await getDocs(q);
      setEstablishmentsToValidate(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    };
    fetchEstablishmentsToValidate();
  }, [db]);

  // Fetch tous les établissements
  useEffect(() => {
    const fetchAllEstablishments = async () => {
      const querySnapshot = await getDocs(collection(db, "etablissements"));
      const establishments = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAllEstablishments(establishments);

      // Détection des doublons
      const duplicates = findDuplicates(establishments);
      setDuplicateEstablishments(duplicates);
      setFilteredEstablishments(establishments);
    };
    fetchAllEstablishments();
  }, [db]);

  // Fetch utilisateurs
  useEffect(() => {
    const fetchUsers = async () => {
      const querySnapshot = await getDocs(collection(db, "users"));
      setUsers(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    };
    fetchUsers();
  }, [db]);

  useEffect(() => {
    const filtered = allEstablishments.filter((est) =>
      est.nom.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredEstablishments(filtered);
  }, [searchQuery, allEstablishments]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredEstablishments.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Détection des doublons par nom et adresse (sans tenir compte de la casse)
  const findDuplicates = (establishments) => {
    const duplicates = [];
    const seen = new Map();

    establishments.forEach((est) => {
      const normalizedNom = est.nom.toLowerCase(); // Normaliser le nom
      const normalizedAdresse = est.adresse.toLowerCase(); // Normaliser l'adresse
      const key = `${normalizedNom}_${normalizedAdresse}`;

      if (seen.has(key)) {
        duplicates.push([seen.get(key), est]); // Ajouter les deux doublons trouvés
      } else {
        seen.set(key, est);
      }
    });
    return duplicates;
  };

  // Valider un établissement
  const validateEstablishment = async (id) => {
    const estRef = doc(db, "etablissements", id);

    // Récupérer les données de l'établissement
    const estDoc = await getDoc(estRef);
    if (!estDoc.exists()) {
      console.error("Établissement non trouvé !");
      return;
    }

    const etablissement = estDoc.data();

    await updateDoc(estRef, { valide: true });

    // Envoi de l'e-mail au gérant
    await fetch(
      "https://us-central1-avis-reel.cloudfunctions.net/sendValidationEmail",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: etablissement.email,
          nom: etablissement.nom,
        }),
      }
    );

    setEstablishmentsToValidate(
      establishmentsToValidate.filter((est) => est.id !== id)
    );
  };

  // Fonction pour mettre à jour un établissement
  const updateEstablishment = async (id, updatedData) => {
    // Si tu mets à jour les coordonnées, assure-toi de les convertir en GeoPoint
    if (updatedData.coord) {
      updatedData.coord = new GeoPoint(
        parseFloat(updatedData.coord.latitude), // Utilise 'latitude' directement
        parseFloat(updatedData.coord.longitude) // Utilise 'longitude' directement
      );
    }

    const estRef = doc(db, "etablissements", id);
    await updateDoc(estRef, updatedData);

    // Mettre à jour les établissements dans l'état local
    setAllEstablishments(
      allEstablishments.map((est) =>
        est.id === id ? { ...est, ...updatedData } : est
      )
    );

    setDuplicateEstablishments(
      duplicateEstablishments.map((est) =>
        est.id === id ? { ...est, ...updatedData } : est
      )
    );
  };

  // Supprimer un établissement
  const deleteEstablishment = async () => {
    if (confirmDeleteId) {
      const estRef = doc(db, "etablissements", confirmDeleteId);
      await deleteDoc(estRef);
      // Rafraîchir la liste après suppression
      setEstablishmentsToValidate(
        establishmentsToValidate.filter((est) => est.id !== confirmDeleteId)
      );
      setAllEstablishments(
        allEstablishments.filter((est) => est.id !== confirmDeleteId)
      );
      setDuplicateEstablishments(
        duplicateEstablishments.filter((est) => est.id !== confirmDeleteId)
      );
      setConfirmDeleteId(null); // Réinitialiser l'ID
      setIsConfirmDeleteVisible(false); // Cacher la fenêtre de confirmation
    }
  };

  // Afficher la fenêtre de confirmation
  const handleDeleteClick = (id) => {
    setConfirmDeleteId(id);
    setIsConfirmDeleteVisible(true);
  };

  // Annuler la suppression
  const cancelDelete = () => {
    setConfirmDeleteId(null);
    setIsConfirmDeleteVisible(false);
  };
  // Supprimer un utilisateur
  const deleteUser = async (id) => {
    const userRef = doc(db, "users", id);
    await deleteDoc(userRef);
    setUsers(users.filter((user) => user.id !== id));
  };

  const renderPagination = () => {
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(filteredEstablishments.length / itemsPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    return (
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-3 py-1 mx-1 bg-gray-300 rounded-md hover:bg-gray-400 disabled:opacity-50"
        >
          Précédent
        </button>
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`px-3 py-1 mx-1 rounded-md ${
              currentPage === number
                ? "bg-blue-500 text-white"
                : "bg-gray-300 hover:bg-gray-400"
            }`}
          >
            {number}
          </button>
        ))}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === pageNumbers.length}
          className="px-3 py-1 mx-1 bg-gray-300 rounded-md hover:bg-gray-400 disabled:opacity-50"
        >
          Suivant
        </button>
      </div>
    );
  };

  return (
    <div className="flex flex-col justify-center items-center p-5 w-full min-h-[600px]">
      <h1 className="text-center text-gray-800 text-4xl mb-5 font-bold">
        Admin Dashboard
      </h1>

      {/* Onglets */}
      <div className="mb-5">
        {["validation", "verification", "users", "reviews", "coupons"].map(
          (tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`px-5 py-2.5 mx-2 rounded-lg cursor-pointer ${
                activeTab === tab
                  ? "bg-green-500 text-white"
                  : "bg-gray-300 text-gray-800"
              }`}
            >
              {tab === "validation" && "Établissements à valider"}
              {tab === "verification" && "Tous les établissements"}
              {tab === "users" && "Utilisateurs"}
              {tab === "reviews" && "Avis"}
              {tab === "coupons" && "Gestion des coupons"}
            </button>
          )
        )}
      </div>

      {/* Contenu des onglets */}
      {activeTab === "validation" && (
        <EstablishmentValidationList
          establishmentsToValidate={establishmentsToValidate}
          validateEstablishment={validateEstablishment}
          handleDeleteClick={handleDeleteClick}
        />
      )}

      {/* Confirmation de la suppression */}
      {isConfirmDeleteVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-semibold text-gray-800 mb-4">
              Êtes-vous sûr de vouloir supprimer cet établissement ?
            </h2>
            <div className="flex justify-between">
              <button
                onClick={cancelDelete}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400"
              >
                Annuler
              </button>
              <button
                onClick={deleteEstablishment}
                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      )}

      {activeTab === "verification" && (
        <div>
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Tous les établissements
          </h2>
          <label>
            <input
              type="checkbox"
              checked={showDuplicatesOnly}
              onChange={() => setShowDuplicatesOnly(!showDuplicatesOnly)}
            />
            Afficher uniquement les doublons
          </label>

          {/* Affichage des établissements filtrés en fonction des doublons */}
          {showDuplicatesOnly ? (
            duplicateEstablishments.length > 0 ? (
              duplicateEstablishments.map((duplicatePair, index) => (
                <div
                  key={index}
                  className="border-2 border-yellow-400 mb-4 p-4 bg-yellow-50 rounded-lg"
                >
                  <h3 className="text-red-600 text-xl font-bold">
                    Doublons trouvés :
                  </h3>
                  {duplicatePair.map((est) => (
                    <div
                      key={est.id}
                      className="p-2 border-b border-gray-300 mb-2 bg-gray-50 rounded-md"
                    >
                      <h4 className="text-lg font-semibold">{est.nom}</h4>
                      <p>
                        <strong>Adresse :</strong> {est.adresse}
                      </p>
                      <p>
                        <strong>Ville :</strong> {est.ville}
                      </p>
                      <p>
                        <strong>Code Postal :</strong> {est.codePostal}
                      </p>
                      <p>
                        <strong>Email :</strong> {est.email}
                      </p>
                      <p>
                        <strong>Téléphone :</strong> {est.telephone}
                      </p>
                      <p>
                        <strong>Type :</strong> {est.type}
                      </p>
                      <button
                        onClick={() => handleDeleteClick(est.id)}
                        className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                      >
                        Supprimer
                      </button>
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <p>Aucun doublon trouvé.</p>
            )
          ) : (
            <>
              <input
                type="text"
                placeholder="Rechercher..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="border p-2 w-full rounded-md mb-4"
              />
              <ul>
                {currentItems.map((est) => (
                  <li key={est.id} className="border border-gray-300 mb-4 p-4">
                    <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                      {est.nom}
                    </h3>
                    <p className="text-sm mb-2">etablissementId = {est.id}</p>
                    {/* Nom */}
                    <div className="mb-2">
                      <span className="text-sm text-gray-500">
                        Nom de l'établissement
                      </span>
                      <input
                        type="text"
                        value={est.nom}
                        onChange={(e) =>
                          updateEstablishment(est.id, { nom: e.target.value })
                        }
                        placeholder="Modifier le nom"
                        className="border p-2 w-full rounded-md"
                      />
                    </div>
                    {/* Adresse */}
                    <div className="mb-2">
                      <span className="text-sm text-gray-500">Adresse :</span>
                      <input
                        type="text"
                        value={est.adresse}
                        onChange={(e) =>
                          updateEstablishment(est.id, {
                            adresse: e.target.value,
                          })
                        }
                        placeholder="Modifier l'adresse"
                        className="border p-2 w-full rounded-md"
                      />
                    </div>
                    {/* Ville */}
                    <div className="mb-2">
                      <span className="text-sm text-gray-500">Ville :</span>
                      <input
                        type="text"
                        value={est.ville}
                        onChange={(e) =>
                          updateEstablishment(est.id, { ville: e.target.value })
                        }
                        placeholder="Modifier la ville"
                        className="border p-2 w-full rounded-md"
                      />
                    </div>
                    {/* Code postal */}
                    <div className="mb-2">
                      <span className="text-sm text-gray-500">
                        Code Postal :
                      </span>
                      <input
                        type="text"
                        value={est.codePostal}
                        onChange={(e) =>
                          updateEstablishment(est.id, {
                            codePostal: e.target.value,
                          })
                        }
                        placeholder="Modifier le code postal"
                        className="border p-2 w-full rounded-md"
                      />
                    </div>
                    {/* Email */}
                    <div className="mb-2">
                      <span className="text-sm text-gray-500">Email :</span>
                      <input
                        type="email"
                        value={est.email}
                        onChange={(e) =>
                          updateEstablishment(est.id, { email: e.target.value })
                        }
                        placeholder="Modifier l'email"
                        className="border p-2 w-full rounded-md"
                      />
                    </div>
                    {/* Téléphone */}
                    <div className="mb-2">
                      <span className="text-sm text-gray-500">Téléphone :</span>
                      <input
                        type="text"
                        value={est.telephone}
                        onChange={(e) =>
                          updateEstablishment(est.id, {
                            telephone: e.target.value,
                          })
                        }
                        placeholder="Modifier le téléphone"
                        className="border p-2 w-full rounded-md"
                      />
                    </div>
                    {/* Type */}
                    <div className="mb-2">
                      <span className="text-sm text-gray-500">Type :</span>
                      <select
                        value={est.type}
                        onChange={(e) =>
                          updateEstablishment(est.id, { type: e.target.value })
                        }
                        className="border p-2 w-full rounded-md"
                      >
                        <option value="">Sélectionner le type</option>
                        <option value="Restaurant">Restaurant</option>
                        <option value="Hôtel">Hôtel</option>
                        <option value="Activités">Activités</option>
                      </select>
                      <span className="text-sm text-gray-500">
                        Type : {est.type}
                      </span>
                    </div>

                    {/* Coordonnées (Latitude et Longitude) */}
                    <div className="mb-2">
                      <span className="text-sm text-gray-500">Latitude :</span>
                      <input
                        type="number"
                        value={
                          est.coord && !isNaN(est.coord.latitude)
                            ? est.coord.latitude
                            : ""
                        } // Vérification de la latitude
                        onChange={(e) => {
                          const newLat = parseFloat(e.target.value);
                          // Vérification de la validité de la latitude
                          if (!isNaN(newLat) && newLat >= -90 && newLat <= 90) {
                            // Mise à jour si la latitude est valide
                            updateEstablishment(est.id, {
                              coord: new GeoPoint(
                                newLat,
                                est.coord ? est.coord.longitude : 0 // Utilise longitude existante ou 0 par défaut
                              ),
                            });
                          } else {
                            // Empêche la mise à jour si latitude invalide
                            console.error(
                              "Latitude invalide. Elle doit être entre -90 et 90."
                            );
                          }
                        }}
                        placeholder="Modifier la latitude"
                        className="border p-2 w-full rounded-md"
                      />
                    </div>
                    <div className="mb-2">
                      <span className="text-sm text-gray-500">Longitude :</span>
                      <input
                        type="number"
                        value={
                          est.coord && !isNaN(est.coord.longitude)
                            ? est.coord.longitude
                            : ""
                        } // Vérification de la longitude
                        onChange={(e) => {
                          const newLng = parseFloat(e.target.value);
                          // Vérification de la validité de la longitude
                          if (
                            !isNaN(newLng) &&
                            newLng >= -180 &&
                            newLng <= 180
                          ) {
                            // Mise à jour si la longitude est valide
                            updateEstablishment(est.id, {
                              coord: new GeoPoint(
                                est.coord ? est.coord.latitude : 0, // Utilise latitude existante ou 0 par défaut
                                newLng
                              ),
                            });
                          } else {
                            // Empêche la mise à jour si longitude invalide
                            console.error(
                              "Longitude invalide. Elle doit être entre -180 et 180."
                            );
                          }
                        }}
                        placeholder="Modifier la longitude"
                        className="border p-2 w-full rounded-md"
                      />
                    </div>

                    {/* Page Facebook */}
                    <div className="mb-2">
                      <span className="text-sm text-gray-500">
                        Page Facebook :
                      </span>
                      <input
                        type="text"
                        value={est.pageFacebook}
                        onChange={(e) =>
                          updateEstablishment(est.id, {
                            pageFacebook: e.target.value,
                          })
                        }
                        placeholder="Modifier la page Facebook"
                        className="border p-2 w-full rounded-md"
                      />
                    </div>
                    {/* Site Internet */}
                    <div className="mb-2">
                      <span className="text-sm text-gray-500">
                        Site Internet :
                      </span>
                      <input
                        type="text"
                        value={est.siteInternet}
                        onChange={(e) =>
                          updateEstablishment(est.id, {
                            siteInternet: e.target.value,
                          })
                        }
                        placeholder="Modifier le site Internet"
                        className="border p-2 w-full rounded-md"
                      />
                    </div>
                    {/* Bouton de suppression */}
                    <div className="mt-4">
                      <button
                        onClick={() => handleDeleteClick(est.id)}
                        className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                      >
                        Supprimer
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
              {renderPagination()}
            </>
          )}
        </div>
      )}
      {activeTab === "reviews" && <ReviewManagement />}

      {activeTab === "users" && (
        <UsersList users={users} deleteUser={deleteUser} />
      )}
      {activeTab === "coupons" && <CouponsManagement />}
    </div>
  );
};

export default AdminDashboard;
