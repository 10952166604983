import { query, collection, where, getDocs } from "firebase/firestore";
import { db } from "../firebase"; // Ajustez selon la structure de votre projet
import { normalizeString } from "../utils/utils";

/**
 * Recherche un établissement par code unique.
 * @param {string} code - Le code unique.
 * @returns {Promise<Object|null>} - Les données de l'établissement ou `null` si non trouvé.
 */
export const searchEtablissementByCode = async (code) => {
  const q = query(
    collection(db, "etablissements"),
    where("code_unique", "array-contains", code)
  );
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    return querySnapshot.docs[0].data(); // Retourne le premier établissement trouvé
  } else {
    return null;
  }
};

export const searchEtablissementsByType = async (type) => {
  const q = query(collection(db, "etablissements"), where("type", "==", type));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

/**
 * Recherche des établissements par nom et lieu.
 * @param {string} searchQuery - Le nom ou partie du nom.
 * @param {string} searchLocation - La ville ou partie du nom de la ville.
 * @returns {Promise<Array>} - Une liste des établissements correspondant.
 */
export const searchEtablissementsByNameAndLocation = async (
  searchQuery,
  searchLocation
) => {
  const normalizedSearchQuery = normalizeString(searchQuery || "");
  const normalizedSearchLocation = normalizeString(searchLocation || "");

  const q = query(collection(db, "etablissements"));
  const querySnapshot = await getDocs(q);

  const results = querySnapshot.docs
    .map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
    .filter((etablissement) => {
      const normalizedNom = etablissement.nom
        ? normalizeString(etablissement.nom)
        : "";
      const normalizedVille = etablissement.ville
        ? normalizeString(etablissement.ville)
        : "";
      const normalizedType = etablissement.type
        ? normalizeString(etablissement.type)
        : "";

      return (
        normalizedNom.includes(normalizedSearchQuery) ||
        normalizedType.includes(normalizedSearchQuery) ||
        normalizedVille.includes(normalizedSearchLocation)
      );
    });

  return results;
};

/**
 * Recherche instantanée dans Firestore pour une barre de recherche.
 * @param {string} searchQuery - La chaîne à rechercher.
 * @returns {Promise<Array>} - Une liste des établissements correspondant.
 */
export const searchEtablissementsInstant = async (searchQuery) => {
  const normalizedQuery = normalizeString(searchQuery);

  const q = query(collection(db, "etablissements"));
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs
    .map((doc) => ({ ...doc.data(), id: doc.id }))
    .filter((etablissement) => {
      const normalizedNom = normalizeString(etablissement.nom || "");
      const normalizedVille = normalizeString(etablissement.ville || "");
      const normalizedType = normalizeString(etablissement.type || "");

      return (
        normalizedNom.includes(normalizedQuery) ||
        normalizedVille.includes(normalizedQuery) ||
        normalizedType.includes(normalizedQuery)
      );
    });
};
