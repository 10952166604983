import React, { useState } from "react";
import { auth } from "../firebase";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pseudo, setPseudo] = useState(""); // Pseudo pour l'inscription
  const [isRegistering, setIsRegistering] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false); // Pour la popup
  const [showPassword, setShowPassword] = useState(false); // Pour afficher/masquer le mot de passe
  const navigate = useNavigate();
  const db = getFirestore();

  // Validation Email
  const validateEmail = (email) =>
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

  // Validation Mot de passe
  const validatePassword = (password) =>
    /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/.test(password);

  // Réinitialisation du mot de passe
  const handlePasswordReset = async () => {
    setError("");
    setSuccessMessage("");
    if (!email) {
      setError(
        "Veuillez entrer votre email pour réinitialiser votre mot de passe."
      );
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage(
        "Un email de réinitialisation de mot de passe a été envoyé. Vérifiez votre boîte mail, y compris les spams."
      );
    } catch (err) {
      if (err.code === "auth/user-not-found") {
        setError("Aucun utilisateur trouvé avec cet email.");
      } else {
        setError("Une erreur est survenue. Veuillez réessayer.");
      }
    }
  };

  // Gestion des soumissions
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    // Validation des champs
    if (!validateEmail(email)) {
      setError("L'email n'est pas valide.");
      return;
    }

    if (!validatePassword(password)) {
      setError(
        "Le mot de passe doit comporter au moins 8 caractères, avec une lettre et un chiffre."
      );
      return;
    }

    try {
      if (isRegistering) {
        // Inscription
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;

        // Ajout dans Firestore
        await setDoc(doc(db, "users", user.uid), {
          email: user.email,
          pseudo: pseudo || "Utilisateur",
          role: "user", // Default role is user
          cadeau: 0,
          date_creation: new Date(),
        });

        // Affichage de la popup
        setShowSuccessPopup(true);
      } else {
        // Connexion
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;

        // Récupérer les données de l'utilisateur
        const userDoc = doc(db, "users", user.uid);
        const userSnap = await getDoc(userDoc);
        if (userSnap.exists()) {
          const userRole = userSnap.data().role;
          // Rediriger selon le rôle
          navigate(userRole === "admin" ? "/adminDashboard" : "/dashboard");
        }
      }
    } catch (err) {
      if (err.code === "auth/email-already-in-use") {
        setError("Cet email est déjà associé à un compte.");
      } else if (err.code === "auth/weak-password") {
        setError("Le mot de passe doit être plus fort.");
      } else {
        setError("Une erreur est survenue, veuillez réessayer.");
      }
    }
  };

  const handlePopupClose = () => {
    setShowSuccessPopup(false);
    setIsRegistering(false); // Revenir au mode connexion
    navigate("/");
  };

  return (
    <div className="min-h-screen flex flex-col justify-between">
      <div className="max-w-sm mx-auto mt-10">
        <h2 className="text-2xl font-bold mb-4">
          {isRegistering ? "Inscription" : "Connexion"}
        </h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {successMessage && (
          <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4">
            {successMessage}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          {isRegistering && (
            <input
              type="text"
              placeholder="Pseudo"
              value={pseudo}
              onChange={(e) => setPseudo(e.target.value)}
              className="w-full p-2 border rounded mb-4"
              required
            />
          )}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border rounded mb-4"
            required
          />
          <div className="mb-4">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 border rounded"
              required
            />
            <label className="flex items-center mt-2 text-sm">
              <input
                type="checkbox"
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
                className="mr-2"
              />
              Afficher le mot de passe
            </label>
          </div>
          <button className="w-full bg-blue-500 text-white p-2 rounded">
            {isRegistering ? "S'inscrire" : "Se connecter"}
          </button>
        </form>
        {!isRegistering && (
          <button
            onClick={handlePasswordReset}
            className="text-blue-500 mt-4 text-center w-full"
          >
            Mot de passe oublié ?
          </button>
        )}
        <p className="mt-4">
          {isRegistering
            ? "Vous avez déjà un compte ?"
            : "Pas encore inscrit ?"}
          <span
            onClick={() => setIsRegistering(!isRegistering)}
            className="text-blue-500 cursor-pointer ml-2"
          >
            {isRegistering ? "Se connecter" : "S'inscrire"}
          </span>
        </p>
      </div>

      {showSuccessPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-lg text-center">
            <h3 className="text-xl font-bold mb-4">Inscription réussie !</h3>
            <p className="mb-4">Votre compte a été créé avec succès.</p>
            <button
              onClick={handlePopupClose}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
