import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import { saveAs } from "file-saver";
import Papa from "papaparse";

// Initialisation Firestore
const db = getFirestore();

/**
 * 📌 Étape 1 : Générer des codes uniques et les stocker dans Firebase.
 * @param {number} n - Nombre de codes à générer.
 */
export const genererCodesUniques = async (n) => {
  const codesCollection = collection(db, "codes_uniq");
  const generatedCodes = [];

  for (let i = 0; i < n; i++) {
    let code;
    let exists = true;

    while (exists) {
      code = Math.random().toString(36).substring(2, 10).toUpperCase();
      const docRef = doc(codesCollection, code);
      const docSnap = await getDoc(docRef);
      exists = docSnap.exists();
    }

    await setDoc(doc(codesCollection, code), {
      etablissement_id: "",
      utilise: false,
    });

    generatedCodes.push(code);
  }

  return generatedCodes;
};

/**
 * 📌 Étape 2 : Télécharger les codes en CSV.
 */
export const exporterCodesCSV = async () => {
  const q = query(
    collection(db, "codes_uniq"),
    where("etablissement_id", "==", "")
  );
  const querySnapshot = await getDocs(q);

  // On récupère les IDs des documents (qui sont les codes uniques)
  const codes = querySnapshot.docs.map((doc) => doc.id);

  if (codes.length === 0) {
    alert("Aucun code disponible à exporter.");
    return;
  }

  // Création du contenu CSV
  const csvContent = "Code unique;etablissementId\r\n" + codes.join("\r\n");
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // Téléchargement du fichier
  saveAs(blob, "codes_uniques.csv");
};

/**
 * 📌 Étape 3 : Assigner un établissement à un code existant.
 * @param {string} code - Le code unique.
 * @param {string} etablissementId - L'ID de l'établissement.
 */
export const assignerCodeAEtablissement = async (code, etablissementId) => {
  const codeRef = doc(db, "codes_uniq", code);
  const etablissementRef = doc(db, "etablissements", etablissementId);

  // Vérifier si le code existe
  const codeSnap = await getDoc(codeRef);
  if (!codeSnap.exists()) {
    console.error("Code introuvable :", code);
    return false;
  }

  // Vérifier si l'établissement existe
  const etablissementSnap = await getDoc(etablissementRef);
  if (!etablissementSnap.exists()) {
    console.error("Établissement introuvable :", etablissementId);
    return false;
  }

  // Mettre à jour l'établissement lié au code dans `codes_uniq`
  await updateDoc(codeRef, { etablissement_id: etablissementId });

  // Récupérer les codes déjà assignés pour éviter les doublons
  const etablissementData = etablissementSnap.data();
  const codesExistants = etablissementData.code_uniq_une_fois || [];

  // Ajouter le code seulement s'il n'est pas déjà dans `code_uniq_une_fois`
  if (!codesExistants.includes(code)) {
    await updateDoc(etablissementRef, {
      code_uniq_une_fois: [...codesExistants, code],
    });
  }

  return true;
};

/**
 * 📌 Étape 4 : Importer un fichier CSV et assigner les codes aux établissements.
 * @param {File} file - Le fichier CSV importé par l'utilisateur.
 */
export const importerCSVEtAssignerCodes = async (file) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true, // Lire la première ligne comme en-tête
      skipEmptyLines: true,
      complete: async (result) => {
        let succes = 0;
        let erreurs = 0;

        for (const row of result.data) {
          const { "Code unique": code, etablissementId } = row;

          if (code && etablissementId) {
            const success = await assignerCodeAEtablissement(
              code,
              etablissementId
            );
            success ? succes++ : erreurs++;
          }
        }

        alert(
          `Importation terminée :\n✅ ${succes} codes assignés\n❌ ${erreurs} erreurs`
        );
        resolve();
      },
      error: (error) => reject(error),
    });
  });
};
