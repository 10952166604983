import React, { useState, useEffect, useRef } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase"; // Assurez-vous que cela pointe vers votre configuration Firebase

const EtablissementDashboard = ({ etablissement }) => {
  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState(etablissement);
  const [message, setMessage] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);
  const questionMarkRef = useRef(null);
  const [currentEtablissement, setCurrentEtablissement] =
    useState(etablissement);

  const reductionsDisponibles = [
    "Aucune réduction",
    "5% sur l'addition",
    "10% sur l'addition",
    "Un café offert",
    "Une entrée gratuite",
    "Un dessert offert",
  ];

  // Fermer la bulle si on clique en dehors
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target) && // Si ce n'est pas la bulle
        !questionMarkRef.current.contains(event.target) // et ce n'est pas le point d'interrogation
      ) {
        setShowTooltip(false); // Fermer la bulle
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside); // Nettoyer l'écouteur quand le composant est démonté
    };
  }, []);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    // Validation des données avant de les envoyer
    if (!formData.nom || !formData.adresse || !formData.telephone) {
      setMessage("Veuillez remplir tous les champs obligatoires.");
      return;
    }

    try {
      const etablissementRef = doc(db, "etablissements", etablissement.id);
      await updateDoc(etablissementRef, formData);
      setCurrentEtablissement(formData);
      setEditing(false);
      setMessage("Informations mises à jour avec succès !");
    } catch (error) {
      console.error("Erreur lors de la mise à jour :", error);
      setMessage("Une erreur est survenue lors de la mise à jour.");
    }
  };

  const handleRequestStickers = async () => {
    try {
      setMessage("Votre demande de stickers a bien été envoyée.");

      // Envoyer une requête à la Cloud Function pour notifier par e-mail
      await fetch(
        "https://us-central1-avis-reel.cloudfunctions.net/sendStickerRequestEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            etablissementNom: etablissement.nom,
            etablissementEmail: etablissement.email,
            etablissementAdresse: etablissement.adresse,
            etablissementVille: etablissement.ville,
            etablissementTelephone: etablissement.telephone,
          }),
        }
      );

      alert("Votre demande de stickers a été envoyée avec succès !");
    } catch (error) {
      console.error("Erreur lors de la demande de stickers :", error);
      setMessage("Une erreur est survenue lors de l'envoi de la demande.");
    }
  };

  if (!etablissement) return null;

  return (
    <div className="mt-6">
      <h3 className="text-2xl font-semibold text-gray-700 mb-4">
        Informations de mon établissement
      </h3>

      {/* Affichage ou édition des informations */}
      {editing ? (
        <div className="space-y-4">
          <div>
            <label className="block font-semibold text-gray-600">
              Nom de l'établissement :
            </label>
            <input
              type="text"
              name="nom"
              value={formData.nom}
              onChange={handleInputChange}
              className="w-full border-2 border-bleu bg-blue-50 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-vert"
            />
          </div>
          <div>
            <label className="block font-semibold text-gray-600">
              Adresse :
            </label>
            <input
              type="text"
              name="adresse"
              value={formData.adresse}
              onChange={handleInputChange}
              className="w-full border-2 border-bleu bg-blue-50 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-vert"
            />
          </div>
          <div>
            <label className="block font-semibold text-gray-600">Ville :</label>
            <input
              type="text"
              name="ville"
              value={formData.ville}
              onChange={handleInputChange}
              className="w-full border-2 border-bleu bg-blue-50 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-vert"
            />
          </div>
          <div>
            <label className="block font-semibold text-gray-600">
              Téléphone :
            </label>
            <input
              type="text"
              name="telephone"
              value={formData.telephone}
              onChange={handleInputChange}
              className="w-full border-2 border-bleu bg-blue-50 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-vert"
            />
          </div>
          <div>
            <label className="block font-semibold text-gray-600">
              Réduction :
            </label>
            <select
              name="reduction"
              value={formData.reduction || "Aucune réduction"}
              onChange={handleInputChange}
              className="w-full border-2 border-bleu bg-blue-50 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-vert"
            >
              {reductionsDisponibles.map((reduction, index) => (
                <option key={index} value={reduction}>
                  {reduction}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block font-semibold text-gray-600">
              Réservation :
            </label>
            <input
              type="text"
              name="reservation"
              value={formData.reservation || ""}
              onChange={handleInputChange}
              className="w-full border-2 border-bleu bg-blue-50 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-vert"
              placeholder="Lien ou informations sur la réservation"
            />
          </div>
          <div>
            <label className="block font-semibold text-gray-600">
              Facebook :
            </label>
            <input
              type="text"
              name="pageFacebook"
              value={formData.pageFacebook}
              onChange={handleInputChange}
              className="w-full border-2 border-bleu bg-blue-50 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-vert"
            />
          </div>
          <div>
            <label className="block font-semibold text-gray-600">
              Site Internet :
            </label>
            <input
              type="text"
              name="siteInternet"
              value={formData.siteInternet}
              onChange={handleInputChange}
              className="w-full border-2 border-bleu bg-blue-50 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-vert"
            />
          </div>
          <button
            onClick={handleSave}
            className="mt-4 bg-bleu text-white px-4 py-2 rounded shadow-sm hover:bg-vert transition duration-200"
          >
            Enregistrer
          </button>
          <button
            onClick={() => setEditing(false)}
            className="mt-4 ml-4 text-rouge"
          >
            Annuler
          </button>
        </div>
      ) : (
        <div className="space-y-4">
          <hr className="my-4 border-gray-300" />
          <p>
            <strong>Nom :</strong> {etablissement.nom}
          </p>
          <hr className="my-4 border-gray-300" />
          <p>
            <strong>Adresse :</strong> {etablissement.adresse},{" "}
            {etablissement.ville}
          </p>
          <hr className="my-4 border-gray-300" />
          <p>
            <strong>Téléphone :</strong> {etablissement.telephone}
          </p>
          <hr className="my-4 border-gray-300" />
          <p>
            <strong>Email :</strong> {etablissement.email}
          </p>
          <hr className="my-4 border-gray-300" />
          <p>
            <strong>Réduction :</strong>{" "}
            {currentEtablissement.reduction || "Aucune réduction"}
          </p>
          <hr className="my-4 border-gray-300" />
          <p>
            <strong>Réservation :</strong>{" "}
            {etablissement.reservation ? (
              <a
                href={etablissement.reservation}
                target="_blank"
                rel="noopener noreferrer"
                className="text-bleu hover:underline"
              >
                {etablissement.reservation}
              </a>
            ) : (
              <span className="inline-flex items-center space-x-2">
                <span
                  ref={questionMarkRef}
                  className="cursor-pointer text-bleu text-2xl animate-bounce ml-2"
                  onClick={() => setShowTooltip(!showTooltip)} // Toggle tooltip on click
                >
                  ?
                </span>
                {showTooltip && (
                  <div
                    ref={tooltipRef} // Lier la bulle à cette référence
                    className="bg-gray-700 text-white text-sm p-2 rounded-md z-10 w-72 md:w-80 mt-2"
                  >
                    Ce champ permet d'ajouter un lien vers votre système de
                    réservation en ligne. Si vous n'avez pas de système, vous
                    pouvez utiliser Fetchef :{" "}
                    <a
                      href="https://pro.fetchef.com/register"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline text-blue-300"
                    >
                      Inscription sur Fetchef
                    </a>
                  </div>
                )}
              </span>
            )}
          </p>

          <hr className="my-4 border-gray-300" />
          <p>
            <strong>Facebook :</strong>{" "}
            <a
              href={etablissement.pageFacebook}
              target="_blank"
              rel="noopener noreferrer"
              className="text-bleu hover:underline"
            >
              {etablissement.pageFacebook}
            </a>
          </p>
          <hr className="my-4 border-gray-300" />
          <p>
            <strong>Site Internet :</strong>{" "}
            <a
              href={etablissement.siteInternet}
              target="_blank"
              rel="noopener noreferrer"
              className="text-bleu hover:underline"
            >
              {etablissement.siteInternet}
            </a>
          </p>
          <hr className="my-4 border-gray-300" />
          <p>
            <strong>Moyenne des avis :</strong> {etablissement.moyenneNote}/5
          </p>
          <hr className="my-4 border-gray-300" />
          <p>
            <strong>Nombre d'avis :</strong> {etablissement.nombreAvis}
          </p>
          <hr className="my-4 border-gray-300" />
          <button
            onClick={() =>
              navigate(`/etablissement/details/${etablissement.id}`)
            }
            className="bg-bleu text-white px-4 py-2 rounded shadow-sm hover:bg-vert transition duration-200"
          >
            Voir la page de l'établissement
          </button>
          <button
            onClick={() => setEditing(true)}
            className="mt-4 bg-gray-200 px-4 py-2 rounded shadow-sm hover:bg-gray-300 transition duration-200"
          >
            Modifier les informations
          </button>
          <button
            onClick={handleRequestStickers}
            className="mt-4 bg-bleu text-white px-4 py-2 rounded shadow-sm hover:bg-vert transition duration-200"
          >
            Recommander des stickers
          </button>
        </div>
      )}

      {/* Message de validation */}
      {message && (
        <div className="mt-4 p-4 bg-green-100 text-green-700 rounded shadow-sm">
          {message}
        </div>
      )}
    </div>
  );
};

export default EtablissementDashboard;
