import React from "react";
import ArrowImage from "../../assets/arrow.png"; // Assure-toi d'avoir ce fichier
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="relative flex flex-col items-center justify-center w-full h-[160px] overflow-hidden bg-transparent">
      {/* SVG pour l'effet d'arrondi */}
      <div className="absolute top-0 left-0 w-full h-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 10"
          preserveAspectRatio="none"
          className="w-full h-full"
        >
          <path
            d="M0,5 Q50,0 100,5 L100,10 L0,10 Z"
            fill="#00ABC7" // Changez cette couleur pour correspondre à votre `bg-bleu`
          />
        </svg>
      </div>

      {/* Flèche vers le haut */}
      <img
        src={ArrowImage}
        alt="Flèche"
        className="absolute top-[5px] cursor-pointer transition-transform duration-300 hover:translate-y-[-5px] z-10"
        onClick={() => window.scrollTo(0, 0)}
      />

      {/* Contenu du footer */}
      <div className="relative flex flex-col items-center justify-end w-full h-full p-4 z-0">
        {/* Liens */}
        <div className="flex gap-4 mb-2">
          <Link
            to="/"
            className="text-white font-bold hover:text-vert transition-colors duration-300"
          >
            Accueil
          </Link>
          <Link
            to="/mentions-legales"
            className="text-white font-bold hover:text-vert transition-colors duration-300"
          >
            Mentions Légales
          </Link>
        </div>
        {/* Texte */}
        <p className="text-white text-center">
          Avis Réel - ©AvisReel.com - Maj : 07/02/2025
        </p>
      </div>
    </footer>
  );
};

export default Footer;
