// EstablishmentValidationList.js
import React from "react";

const EstablishmentValidationList = ({
  establishmentsToValidate,
  validateEstablishment,
  handleDeleteClick,
}) => {
  return (
    <div className="p-8">
      <h2 className="text-3xl font-semibold text-gray-800 mb-6">
        Établissements à valider
      </h2>
      {establishmentsToValidate.length === 0 ? (
        <p>Aucun établissement à valider.</p>
      ) : (
        establishmentsToValidate.map((est) => (
          <div
            key={est.id}
            className="border p-6 mb-4 bg-white rounded-lg shadow-lg flex items-center justify-between"
          >
            {/* Affichage de l'image de l'établissement */}
            {est.photoURL && (
              <img
                src={est.photoURL}
                alt={est.nom}
                className="w-20 h-20 object-cover rounded-full mr-4"
              />
            )}

            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-900">{est.nom}</h3>
              <p className="text-sm text-gray-600">Adresse : {est.adresse}</p>
              <p className="text-sm text-gray-600">Email : {est.email}</p>
              <p className="text-sm text-gray-600">
                Téléphone : {est.telephone}
              </p>
              <p className="text-sm text-gray-600">Type : {est.type}</p>
              <p className="text-sm text-gray-600">Demande : {est.demande}</p>
            </div>

            <div className="flex space-x-4">
              {/* Bouton pour valider */}
              <button
                onClick={() => validateEstablishment(est.id)}
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition-colors"
              >
                Valider
              </button>

              {/* Bouton pour supprimer avec une croix rouge */}
              <button
                onClick={() => handleDeleteClick(est.id)}
                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition-colors flex items-center"
              >
                <span className="mr-2">Supprimer</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default EstablishmentValidationList;
