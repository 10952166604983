import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";

const AutoLogout = () => {
  const [user] = useAuthState(auth);

  // Définir la fonction resetTimeout à l'extérieur de useEffect
  const resetTimeout = (timeout) => {
    clearTimeout(timeout);
    return setTimeout(() => {
      signOut(auth); // Déconnecter après 30 minutes d'inactivité
    }, 30 * 60 * 1000); // Réinitialiser le délai
  };

  useEffect(() => {
    let timeout;
    if (user) {
      // Déconnexion après 30 minutes d'inactivité
      timeout = setTimeout(() => {
        signOut(auth);
      }, 30 * 60 * 1000); // 30 minutes en millisecondes

      // Réinitialiser le délai d'inactivité à chaque interaction
      window.addEventListener("mousemove", () => {
        timeout = resetTimeout(timeout);
      });
      window.addEventListener("keydown", () => {
        timeout = resetTimeout(timeout);
      });
    }

    // Nettoyer les écouteurs et le timeout lors du démontage du composant
    return () => {
      clearTimeout(timeout);
      window.removeEventListener("mousemove", resetTimeout);
      window.removeEventListener("keydown", resetTimeout);
    };
  }, [user]);

  return null; // Ce composant n'affiche rien
};

export default AutoLogout;
