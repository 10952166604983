import React, { useState, useEffect } from "react";
import { db } from "../../firebase"; // Assurez-vous que le chemin est correct
import {
  collection,
  query,
  orderBy,
  limit,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const LatestReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [expandedAvis, setExpandedAvis] = useState({}); // État pour gérer l'extension des commentaires
  const navigate = useNavigate();

  useEffect(() => {
    // Fonction pour récupérer les 5 derniers avis
    const fetchReviews = async () => {
      try {
        // Référence à la collection "avis"
        const avisRef = collection(db, "avis");

        // Créer une requête pour récupérer les 5 derniers avis triés par date
        const q = query(avisRef, orderBy("date", "desc"), limit(5));

        // Récupération des avis
        const avisSnapshot = await getDocs(q);

        let avisList = [];

        // On boucle sur les avis récupérés
        for (let docSnap of avisSnapshot.docs) {
          const avisData = docSnap.data();

          // On récupère l'établissement lié à l'avis
          const etablissementRef = doc(
            db,
            "etablissements",
            avisData.id_etablissement
          );
          const etablissementDoc = await getDoc(etablissementRef);

          if (etablissementDoc.exists()) {
            const etablissementData = etablissementDoc.data();

            // Ajouter les informations de l'établissement (nom, ville, photoURL)
            avisData.etablissement = etablissementData.nom;
            avisData.ville = etablissementData.ville;
            avisData.photoURL = etablissementData.photoURL; // Ajout de la photo
          }

          avisList.push(avisData);
        }

        // On met à jour l'état avec les 5 derniers avis
        setReviews(avisList);
      } catch (error) {
        console.error("Erreur lors de la récupération des avis :", error);
      }
    };

    fetchReviews();
  }, []);

  // Fonction pour basculer l'affichage complet du commentaire
  const toggleExpandAvis = (index) => {
    setExpandedAvis((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <section className="bg-gray-100 p-8 flex flex-col items-center">
      <h2 className="text-xl font-bold font-lobster mb-4">
        Derniers Avis déposés
      </h2>
      {reviews.length > 0 ? (
        <ul className="flex flex-col items-center">
          {reviews.map((review, index) => (
            <li
              key={`${review.id}_${review.date.seconds}`} // Utilisation d'une clé combinée unique
              className={`mb-4 p-4 bg-white shadow-md rounded-lg w-full max-w-md cursor-pointer border-l-4 ${
                index < 5 ? "border-bleu" : "border-transparent"
              } hover:border-vert hover:bg-gray-100`} // Classe hover ajoutée sur la `li` complète
              onClick={() =>
                navigate(`/etablissement/details/${review.id_etablissement}`)
              }
            >
              <div className="flex items-center mb-2">
                {review.photoURL && (
                  <img
                    src={review.photoURL}
                    alt={`${review.etablissement}`}
                    className="w-16 h-16 rounded-full object-cover mr-4"
                  />
                )}
                <div>
                  <div className="font-bold text-lg">
                    {/* Lien vers la page de détail de l'établissement */}
                    <span className="text-bleu hover:text-vert">
                      {review.etablissement} ({review.ville})
                    </span>
                  </div>

                  {/* Affichage du commentaire avec "Voir plus" si nécessaire */}
                  <p className="text-gray-700 mt-2">
                    {expandedAvis[index] || review.commentaire.length <= 100
                      ? review.commentaire
                      : `${review.commentaire.slice(0, 100)}...`}
                    {review.commentaire.length > 100 && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Empêche la redirection lors du clic sur "Voir plus"
                          toggleExpandAvis(index);
                        }}
                        className="text-vert font-semibold ml-2"
                      >
                        {expandedAvis[index] ? "Voir moins" : "Voir plus"}
                      </button>
                    )}
                  </p>
                  <div className="flex items-center mt-2">
                    <span className="mr-2">Note:</span>
                    <span>{review.note}</span>{" "}
                    {/* Affichage de la note en numérique */}
                  </div>
                  <div className="text-gray-500 text-sm mt-2">
                    Posté par {review.auteur} le{" "}
                    {new Date(review.date.seconds * 1000).toLocaleDateString()}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>Chargement des avis...</p>
      )}
    </section>
  );
};

export default LatestReviews;
