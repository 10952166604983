import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const ConfirmationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { etablissementId } = location.state || {}; // Utilisation de l'ID de l'établissement

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg max-w-4xl mx-auto mt-12 mb-6">
      <h1 className="text-3xl font-bold text-center">
        Merci pour votre avis !
      </h1>
      <p className="text-lg text-center mt-4">
        Votre avis a bien été pris en compte. Nous vous remercions pour votre
        temps et votre retour ! Vous recevrez un e-mail avec votre code de
        réduction sous peu. Pensez à vérifier vos spams.
      </p>

      <div className="text-center mt-6">
        <button
          onClick={() => navigate(`/etablissement/details/${etablissementId}`)}
          className="w-full p-3 bg-bleu text-white font-bold rounded-lg hover:bg-vert transition mt-6 mb-6"
        >
          Voir les avis de l'établissement
        </button>
      </div>
    </div>
  );
};

export default ConfirmationPage;
