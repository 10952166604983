import React, { useState } from "react";

const UsersList = ({ users, deleteUser }) => {
  // Fonction pour convertir Firestore Timestamp en une date lisible
  const formatDate = (date) => {
    if (!date) return "Non défini";
    if (date.seconds) {
      return new Date(date.seconds * 1000).toLocaleString("fr-FR");
    }
    return new Date(date).toLocaleString("fr-FR");
  };

  // Trier les utilisateurs par date (les plus récents en premier)
  const sortedUsers = [...users].sort(
    (a, b) => (b.date_creation?.seconds || 0) - (a.date_creation?.seconds || 0)
  );

  // Pagination
  const usersPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(sortedUsers.length / usersPerPage);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = sortedUsers.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <div>
      <h2 className="text-3xl font-semibold text-gray-800 mb-4">
        Liste des utilisateurs
      </h2>

      {currentUsers.map((user) => (
        <div key={user.id} className="border border-gray-300 m-2.5 p-2.5">
          <p>
            <strong>Pseudo :</strong> {user.pseudo || "Inconnu"} |{" "}
            <strong>Email :</strong> {user.email || "Non fourni"} |{" "}
            <strong>Role :</strong> {user.role || "Aucun"} |{" "}
            <strong>Inscrit le :</strong> {formatDate(user.date_creation)}
          </p>
          <div className="flex justify-center items-center">
            <button
              onClick={() => deleteUser(user.id)}
              className="bg-red-500 text-white border border-red-700 hover:bg-red-600 rounded p-2"
            >
              Supprimer
            </button>
          </div>
        </div>
      ))}

      {/* Pagination */}
      <div className="flex justify-center mt-4 space-x-2">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="px-3 py-1 bg-gray-300 rounded disabled:opacity-50"
        >
          Précédent
        </button>
        <span className="px-3 py-1 bg-gray-200 rounded">
          {currentPage} / {totalPages}
        </span>
        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
          className="px-3 py-1 bg-gray-300 rounded disabled:opacity-50"
        >
          Suivant
        </button>
      </div>
    </div>
  );
};

export default UsersList;
