import {
  genererCodesUniques,
  exporterCodesCSV,
  assignerCodeAEtablissement,
  importerCSVEtAssignerCodes,
} from "../utils/codesUtils";
import { useState } from "react";

const CouponsManagement = () => {
  const [nombreCodes, setNombreCodes] = useState(10);
  const [codeAAttribuer, setCodeAAttribuer] = useState("");
  const [etablissementId, setEtablissementId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Générer les codes
  const handleGenererCodes = async () => {
    setIsLoading(true);
    const codes = await genererCodesUniques(nombreCodes);
    alert(`Codes générés :\n${codes.join("\n")}`);
    setIsLoading(false);
  };

  const handleAssignerCode = async () => {
    setIsLoading(true);
    if (await assignerCodeAEtablissement(codeAAttribuer, etablissementId)) {
      alert(
        `Le code ${codeAAttribuer} a été attribué à l'établissement ${etablissementId}`
      );
    } else {
      alert("Code non trouvé !");
    }
    setIsLoading(false);
  };

  // Gestion de l'importation CSV
  const handleImportCSV = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);
      await importerCSVEtAssignerCodes(file);
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center p-5">
      <h1 className="text-3xl font-bold mb-5">Gestion des Codes Uniques</h1>

      {/* Génération des codes */}
      <div className="mb-5">
        <input
          type="number"
          value={nombreCodes}
          onChange={(e) => setNombreCodes(parseInt(e.target.value))}
          className="border p-2 mr-2"
        />
        <button
          onClick={handleGenererCodes}
          disabled={isLoading}
          className="bg-green-500 text-white px-4 py-2 rounded-md"
        >
          {isLoading ? "Génération..." : `Générer ${nombreCodes} codes`}
        </button>
      </div>

      {/* Exportation en CSV */}
      <button
        onClick={exporterCodesCSV}
        className="bg-blue-500 text-white px-4 py-2 rounded-md mb-5"
      >
        Télécharger les codes en CSV
      </button>

      {/* Attribution des codes */}
      <div>
        <input
          type="text"
          placeholder="Code unique"
          value={codeAAttribuer}
          onChange={(e) => setCodeAAttribuer(e.target.value)}
          className="border p-2 mr-2"
        />
        <input
          type="text"
          placeholder="ID de l'établissement"
          value={etablissementId}
          onChange={(e) => setEtablissementId(e.target.value)}
          className="border p-2 mr-2"
        />
        <button
          onClick={handleAssignerCode}
          className="bg-yellow-500 text-white px-4 py-2 rounded-md"
        >
          Assigner Code
        </button>
      </div>
      {/* Importation CSV */}
      <div className="mt-5">
        <input type="file" accept=".csv" onChange={handleImportCSV} />
      </div>
    </div>
  );
};
export default CouponsManagement;
