import React, { useState } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { auth, db } from "../../firebase";
import {
  collection,
  addDoc,
  GeoPoint,
  doc,
  query,
  where,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";

const RegisterEstablishment = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pseudo, setPseudo] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [accountExists, setAccountExists] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  // Vérifie si un compte existe déjà pour l'email
  const checkAccountExists = async () => {
    if (!email) {
      alert("Veuillez saisir un email.");
      return;
    }
    // Vérification si l'email est vide ou invalide
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      alert("Veuillez saisir un email.");
      return;
    } else if (!emailRegex.test(email)) {
      alert("Veuillez saisir un email valide au format exemple@domaine.com");
      return; // Met le focus sur le champ email
    }
    try {
      // Vérifiez si un utilisateur existe avec cet email
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setAccountExists(true); // Email existe
      } else {
        setAccountExists(false); // Email n'existe pas
      }
      setEmailChecked(true); // Email vérifié
    } catch (error) {
      console.error("Erreur lors de la vérification de l'email :", error);
    }
  };

  // Gestion de la saisie d'email
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailChecked(false); // Réinitialise la vérification si l'email change
  };

  // Gestion du fichier image
  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setPhoto(e.target.files[0]);
    }
  };

  const validateCodePostal = (code) => /^[0-9]{5}$/.test(code);
  const validatePassword = (pwd) =>
    /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/.test(pwd);

  // Envoi des données du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Collecte des données du formulaire
    const formData = {
      civilite: e.target[0].value,
      nomPatron: e.target[1].value,
      prenom: e.target[2].value,
      type: e.target[3].value,
      nom: e.target[4].value,
      adresse: e.target[5].value,
      codePostal: e.target[6].value,
      ville: e.target[7].value,
      pays: e.target[8].value,
      telephone: e.target[9].value,
      siteInternet: e.target[10].value,
      pageFacebook: e.target[11].value,
      demande: e.target[12].value,
      email: e.target[13].value,
      avis: [],
      code_uniq_une_fois: [],
      code_unique: [],
      moyenneNote: 0.0,
      nombreAvis: 0,
      date_creation: new Date(),
      reduction: "Aucune réduction",
      coord: new GeoPoint(0, 0),
    };

    try {
      let userId;
      if (!accountExists) {
        // Créer un compte utilisateur
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        userId = userCredential.user.uid;

        // Ajouter le pseudo et rôle utilisateur
        await setDoc(doc(db, "users", userId), {
          email,
          pseudo: pseudo || "Utilisateur",
          role: "user",
          cadeau: 0,
          date_creation: new Date(),
        });
      } else {
        // Si l'email existe déjà
        userId = email;
      }

      let photoURL = null;

      // Si une photo est sélectionnée, upload dans Firebase Storage
      if (photo) {
        const storage = getStorage();
        const fileName = `${formData.nom}_${Date.now()}`;
        const storageRef = ref(storage, `etablissements/${fileName}`);
        await uploadBytes(storageRef, photo);
        photoURL = await getDownloadURL(storageRef);
      }

      // Appel de la fonction Cloud pour envoyer un email
      if (formData.email) {
        const response = await fetch(
          "https://us-central1-avis-reel.cloudfunctions.net/sendRegistrationEmail",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData),
          }
        );

        if (!response.ok) {
          throw new Error("L'envoi de l'email a échoué");
        }
      } else {
        console.warn(
          "Aucune adresse email fournie, l'étape d'envoi d'email est ignorée."
        );
      }

      // Enregistrer les données dans Firestore uniquement après le succès de l'email
      const etablissementsRef = collection(db, "etablissements");
      await addDoc(etablissementsRef, {
        ...formData,
        photoURL,
        valide: false,
      });

      // Succès : afficher un message et réinitialiser le formulaire
      setSuccessMessage(
        "Votre demande a été envoyée avec succès ! Votre établissement sera étudié, et vous recevrez un email lorsqu'il sera en ligne. Pensez à vérifier vos spams."
      );
      setIsPopupOpen(true); // Ouvre la popup
      e.target.reset();
      setEmail("");
      setPassword("");
      setPseudo("");
      setCodePostal("");
      setPhoto(null);
    } catch (error) {
      console.error("Erreur :", error);
      alert(
        `Une erreur s'est produite. Veuillez réessayer. Détail de l'erreur: ${error.message}`
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center gap-4 p-6 mx-auto max-w-3xl">
      {/* Titre principal */}
      <h1 className="text-4xl font-lobster font-bold text-center text-black mb-4">
        Inscrivez votre Etablissement
      </h1>

      {/* Sous-titre */}
      <h2 className="text-2xl font-lobster font-bold text-black mb-4">
        Formulaire d'inscription
      </h2>

      {/* Instructions */}
      <div className="w-full p-4 bg-vert rounded-lg text-center text-black mb-6">
        Merci de bien vouloir remplir ce formulaire pour inscrire votre
        établissement. <br />
        <span className="text-red-600 font-bold">*</span> Les champs marqués
        sont obligatoires.
      </div>

      {/* Formulaire */}
      <form
        className="w-full p-6 bg-bleu rounded-lg space-y-4 text-black"
        onSubmit={handleSubmit}
      >
        {/* Champs du formulaire */}
        <div className="flex flex-col">
          <label>Civilité</label>
          <select className="p-2 rounded">
            <option>Monsieur</option>
            <option>Madame</option>
            <option>Mademoiselle</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label>Nom</label>
          <input type="text" className="p-2 rounded" />
        </div>
        <div className="flex flex-col">
          <label>Prénom</label>
          <input type="text" className="p-2 rounded" />
        </div>
        <div className="flex flex-col">
          <label>
            Type d'établissement
            <span className="text-red-600 font-bold">*</span>
          </label>
          <select className="p-2 rounded" required>
            <option>Restaurant</option>
            <option>Hôtel</option>
            <option>Activités</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label>
            Nom de l'établissement
            <span className="text-red-600 font-bold">*</span>
          </label>
          <input type="text" className="p-2 rounded" required />
        </div>
        <div className="flex flex-col">
          <label>
            Adresse<span className="text-red-600 font-bold">*</span>
          </label>
          <input type="text" className="p-2 rounded" required />
        </div>
        <div className="flex flex-col">
          <label>
            Code postal<span className="text-red-600 font-bold">*</span>
          </label>
          <input
            type="text"
            value={codePostal}
            onChange={(e) => setCodePostal(e.target.value)}
            className="p-2 rounded"
            required
          />
          {!validateCodePostal(codePostal) && codePostal.length > 0 && (
            <p className="text-red-500 text-sm">
              Le code postal doit contenir 5 chiffres.
            </p>
          )}
        </div>
        <div className="flex flex-col">
          <label>
            Ville<span className="text-red-600 font-bold">*</span>
          </label>
          <input type="text" className="p-2 rounded" required />
        </div>
        <div className="flex flex-col">
          <label>Pays</label>
          <input type="text" className="p-2 rounded" />
        </div>
        <div className="flex flex-col">
          <label>
            Téléphone<span className="text-red-600 font-bold">*</span>
          </label>
          <input type="tel" className="p-2 rounded" required />
        </div>
        <div className="flex flex-col">
          <label>Site internet</label>
          <input type="url" name="siteInternet" className="p-2 rounded" />
        </div>
        <div className="flex flex-col">
          <label>Page Facebook</label>
          <input type="url" name="pageFacebook" className="p-2 rounded" />
        </div>
        <div className="flex flex-col">
          <label>
            Votre demande<span className="text-red-600 font-bold">*</span>
          </label>
          <textarea name="demande" className="p-2 rounded" />
        </div>
        <div className="flex flex-col">
          <label>
            Email<span className="text-red-600 font-bold">*</span>
          </label>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            className="p-2 rounded"
            required
          />
        </div>
        <button
          type="button"
          onClick={checkAccountExists}
          className="w-full p-2 bg-vert text-white rounded-lg font-bold"
          required
        >
          Vérifier l'email <span className="text-red-600 font-bold">*</span>
        </button>
        {emailChecked && (
          <p className={`mt-2 ${accountExists ? "text-vert" : "text-vert"}`}>
            {accountExists
              ? "Cet email est bien associé à un compte."
              : "Cet email peut être utilisé pour créer un compte."}
          </p>
        )}
        {emailChecked && !accountExists && (
          <>
            <div>
              <label>Mot de passe *</label>
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full p-2 border rounded"
              />
              {!validatePassword(password) && password.length > 0 && (
                <p className="text-red-500 text-sm">
                  Le mot de passe doit contenir au moins 8 caractères, une
                  lettre et un chiffre.
                </p>
              )}
              <div className="mt-1">
                <input
                  type="checkbox"
                  id="showPassword"
                  checked={showPassword}
                  onChange={() => setShowPassword(!showPassword)}
                />
                <label htmlFor="showPassword" className="ml-2">
                  Afficher le mot de passe
                </label>
              </div>
            </div>
            <div>
              <label>Pseudo *</label>
              <input
                type="text"
                value={pseudo}
                onChange={(e) => setPseudo(e.target.value)}
                required
                className="w-full p-2 border rounded"
              />
            </div>
          </>
        )}
        <div className="flex flex-col">
          <label>Photo</label>
          <input
            type="file"
            className="p-2 rounded"
            onChange={handleFileChange}
          />
        </div>
        {/* Consentement */}
        <div className="flex items-center">
          <input type="checkbox" className="mr-2" required />
          <label>
            J'accepte l'utilisation de mes données personnelles à des fins de
            validation et de suivi de mon inscription.
            <span className="text-red-600 font-bold">*</span>
          </label>
        </div>
        <div className="flex items-center">
          <input type="checkbox" className="mr-2" required />
          <label>
            Je certifie être un représentant officiel de l'établissement et que
            les informations fournies sont exactes. En soumettant une photo, je
            confirme disposer des droits nécessaires.
            <span className="text-red-600 font-bold">*</span>
          </label>
        </div>
        {/* Bouton de soumission */}
        <button
          type="submit"
          disabled={loading}
          className="w-full p-2 bg-vert text-white rounded-lg font-bold"
        >
          {loading ? "Chargement..." : "Valider"}
        </button>
      </form>
      {/* Popup */}
      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-lg">
            <h2 className="text-2xl font-bold mb-4 text-center text-green-800">
              Succès !
            </h2>
            <p className="text-black text-center mb-6">{successMessage}</p>
            <button
              className="w-full p-2 bg-vert text-white rounded-lg font-bold"
              onClick={() => setIsPopupOpen(false)}
            >
              Fermer
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegisterEstablishment;
