import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { fetchDynamicDepartmentsAndCities } from "../utils/departmentNames";
import departmentNames from "../utils/departmentNames";

const Leaderboard = () => {
  const [rankings, setRankings] = useState([]);
  const [filters, setFilters] = useState({
    department: "",
    city: "",
    type: "",
  });
  const [departments, setDepartments] = useState([]);
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const types = ["Restaurant", "Hôtel", "Activités"]; // Types prédéfinis

  useEffect(() => {
    const fetchData = async () => {
      const { departments, cities } = await fetchDynamicDepartmentsAndCities();
      setDepartments(departments);
      setCities(cities);
    };

    fetchData();
  }, []);

  const fetchLeaderboard = async () => {
    try {
      setIsLoading(true);
      const etablissementsRef = collection(db, "etablissements");
      const etablissementsSnapshot = await getDocs(etablissementsRef);

      const filteredEtablissements = await Promise.all(
        etablissementsSnapshot.docs.map(async (doc) => {
          const data = doc.data();

          // Vérifie que l'établissement est validé
          if (data.valide !== true) {
            return null; // Si l'établissement n'est pas validé, on ne le prend pas en compte
          }

          const department = data.codePostal?.toString().substring(0, 2);

          if (
            (filters.department && department !== filters.department) ||
            (filters.city && data.ville !== filters.city) ||
            (filters.type && data.type !== filters.type)
          ) {
            return null;
          }

          const avisRef = collection(db, "avis");
          const avisQuery = query(
            avisRef,
            where("id_etablissement", "==", doc.id)
          );
          const avisSnapshot = await getDocs(avisQuery);

          const totalNote = avisSnapshot.docs.reduce(
            (sum, avisDoc) => sum + avisDoc.data().note,
            0
          );
          const moyenneNote =
            avisSnapshot.size > 0 ? totalNote / avisSnapshot.size : 0;

          return {
            id: doc.id,
            ...data,
            averageRating: moyenneNote.toFixed(2),
            avisCount: avisSnapshot.size, // Nombre d'avis
          };
        })
      );

      const sortedEtablissements = filteredEtablissements
        .filter((etablissement) => etablissement !== null)
        .sort((a, b) => b.averageRating - a.averageRating);

      setRankings(sortedEtablissements.slice(0, 10));
    } catch (error) {
      console.error("Erreur lors de la récupération des classements :", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLeaderboard();
  }, [filters]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });

    // Réinitialise le filtre de ville si le département change
    if (name === "department") {
      setFilters((prev) => ({ ...prev, city: "" }));
    }
  };

  const resetFilters = () => {
    setFilters({ department: "", city: "", type: "" });
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold font-lobster text-center mb-6">
        TOP 10 Meilleures notations des Établissements
      </h1>

      <div className="filters bg-gray-100 p-4 rounded mb-6">
        <h2 className="text-lg font-semibold mb-4">Filtres</h2>
        <div className="flex gap-4 flex-wrap">
          <select
            name="department"
            value={filters.department}
            onChange={handleFilterChange}
            className="border rounded px-3 py-2"
          >
            <option value="">Tous les départements</option>
            {departments.map((dept) => (
              <option key={dept} value={dept}>
                {departmentNames[dept] || dept}
              </option>
            ))}
          </select>

          {filters.department && (
            <select
              name="city"
              value={filters.city}
              onChange={handleFilterChange}
              className="border rounded px-3 py-2"
            >
              <option value="">Toutes les villes</option>
              {(cities[filters.department] || []).map((city) => (
                <option key={city} value={city}>
                  {city}
                </option>
              ))}
            </select>
          )}

          <select
            name="type"
            value={filters.type}
            onChange={handleFilterChange}
            className="border rounded px-3 py-2"
          >
            <option value="">Tous les types</option>
            {types.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>

          <button
            onClick={resetFilters}
            className="bg-red-500 text-white px-4 py-2 rounded"
          >
            Réinitialiser
          </button>
        </div>
      </div>

      <div className="rankings grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {isLoading ? (
          <p>Chargement des classements...</p>
        ) : rankings.length > 0 ? (
          rankings.map((etablissement, index) => (
            <div
              key={etablissement.id}
              className="bg-white shadow rounded p-4 hover:shadow-lg transition flex flex-col items-center text-center group"
              onClick={() =>
                navigate(`/etablissement/details/${etablissement.id}`)
              }
            >
              <img
                src={etablissement.photoURL || "/placeholder.jpg"}
                alt={etablissement.nom}
                className="w-full h-32 object-cover mb-4 rounded"
              />
              <h3 className="text-xl font-bold group-hover:text-vert transition-colors">
                #{index + 1} - {etablissement.nom}
              </h3>
              <p>Note Moyenne : {etablissement.averageRating}</p>
              <p>Nombre d'avis : {etablissement.avisCount}</p>{" "}
              {/* Affichage du nombre d'avis */}
              <p>Ville : {etablissement.ville}</p>
              <p>Type : {etablissement.type}</p>
            </div>
          ))
        ) : (
          <p>Aucun établissement trouvé.</p>
        )}
      </div>
    </div>
  );
};

export default Leaderboard;
