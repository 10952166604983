import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

// Importer Firebase et Firestore
import { initializeApp } from "firebase/app";
import { getFirestore, setLogLevel } from "firebase/firestore"; // Import Firestore et setLogLevel

// Importer la configuration Firebase depuis firebase.js
import { firebaseConfig } from "./firebase"; // Assurez-vous que la configuration est exportée

// Initialisation de Firebase et de Firestore
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Activer les logs Firebase pour Firestore
setLogLevel("warn");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
