import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchSearchResults } from "../services/firebaseUtils";
import { fetchDynamicDepartmentsAndCities } from "../utils/departmentNames";
import departmentNames from "../utils/departmentNames";

const SearchResultsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("query");

  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [filters, setFilters] = useState({
    department: "",
    city: "",
    type: "",
  });
  const [departments, setDepartments] = useState([]);
  const [cities, setCities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Page actuelle pour la pagination
  const itemsPerPage = 10; // Nombre d'éléments par page

  const types = ["Restaurant", "Hôtel", "Activités"];

  // Fetch departments and cities for filtering
  useEffect(() => {
    const fetchFilters = async () => {
      const { departments, cities } = await fetchDynamicDepartmentsAndCities();
      setDepartments(departments);
      setCities(cities);
    };
    fetchFilters();
  }, []);

  const fetchResults = async () => {
    if (query) {
      setIsLoading(true);
      try {
        const cacheKey = `search_results_${query}_${JSON.stringify(filters)}`;
        const cachedData = JSON.parse(localStorage.getItem(cacheKey));
        const cacheExpiration = 3600 * 1000; // 1 heure en millisecondes

        if (cachedData && Date.now() - cachedData.timestamp < cacheExpiration) {
          // Utiliser les données du cache si elles sont valides
          setResults(cachedData.results);
        } else {
          // Rechercher de nouvelles données si le cache est invalide ou expiré
          await fetchSearchResults(query, setResults, filters);
          localStorage.setItem(
            cacheKey,
            JSON.stringify({
              results,
              timestamp: Date.now(), // Ajouter un horodatage
            })
          );
        }
      } catch (error) {
        console.error("Erreur lors de la recherche :", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setResults([]);
    fetchResults();
    setCurrentPage(1); // Réinitialise à la première page quand les filtres changent
  }, [query, filters]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });

    if (name === "department") {
      setFilters((prev) => ({ ...prev, city: "" }));
    }
  };

  const resetFilters = async () => {
    setFilters({ department: "", city: "", type: "" });
    setResults([]); // Réinitialise les résultats
    setCurrentPage(1); // Réinitialise la pagination
    try {
      setIsLoading(true);
      await fetchSearchResults(query, setResults, {
        department: "",
        city: "",
        type: "",
      });
      localStorage.setItem(`search_results_${query}_`, JSON.stringify(results)); // Stocker dans le cache
    } catch (error) {
      console.error("Erreur lors de la réinitialisation des résultats:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Découpe les résultats en fonction de la page actuelle
  const paginatedResults = results.slice(0, currentPage * itemsPerPage);

  return (
    <div className="p-6 min-h-[570px] flex flex-col items-center">
      <h1 className="text-2xl font-bold mb-4 text-center">
        Résultats de recherche pour "{query}"
      </h1>
      {/* Bouton pour réinitialiser la recherche principale */}
      <div className="text-center mb-6">
        <button
          onClick={() => {
            resetFilters(); // Réinitialise les filtres et résultats
            navigate("/search-results?query="); // Réinitialise la recherche principale
          }}
          className="bg-red-500 text-white px-4 py-2 rounded"
        >
          Réinitialiser la recherche
        </button>
      </div>

      <div className="filters bg-gray-100 p-4 rounded mb-6">
        <div className="category-buttons flex gap-4 mb-6">
          {types.map((type) => (
            <button
              key={type}
              className={`px-4 py-2 rounded ${
                query === type ? "bg-blue-500 text-white" : "bg-gray-200"
              }`}
              onClick={() => {
                resetFilters();
                navigate(`/search-results?query=${type}`);
              }}
            >
              {type}
            </button>
          ))}
        </div>

        <h2 className="text-lg font-semibold mb-4">Filtres</h2>
        <div className="flex gap-4 flex-wrap">
          <select
            name="department"
            value={filters.department}
            onChange={handleFilterChange}
            className="border rounded px-3 py-2"
          >
            <option value="">Tous les départements</option>
            {departments.map((dept) => (
              <option key={dept} value={dept}>
                {departmentNames[dept] || dept}
              </option>
            ))}
          </select>

          {filters.department && (
            <select
              name="city"
              value={filters.city}
              onChange={handleFilterChange}
              className="border rounded px-3 py-2"
            >
              <option value="">Toutes les villes</option>
              {(cities[filters.department] || []).map((city) => (
                <option key={city} value={city}>
                  {city}
                </option>
              ))}
            </select>
          )}

          <button
            onClick={resetFilters}
            className="bg-red-500 text-white px-4 py-2 rounded"
          >
            Réinitialiser
          </button>
        </div>
      </div>

      {isLoading ? (
        <p>Chargement...</p>
      ) : results.length === 0 ? (
        <p>Aucun résultat trouvé pour "{query}".</p>
      ) : (
        <>
          <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {paginatedResults.map((etablissement) => (
              <li
                key={etablissement.id}
                className="p-4 bg-gray-100 rounded-lg shadow hover:bg-gray-200 cursor-pointer flex flex-col items-center text-center"
                onClick={() =>
                  navigate(`/etablissement/details/${etablissement.id}`)
                }
              >
                {etablissement.photoURL && (
                  <img
                    src={etablissement.photoURL}
                    alt={`${etablissement.nom}`}
                    className="w-24 h-24 mb-2 rounded object-cover"
                  />
                )}
                <h2 className="font-bold text-lg">{etablissement.nom}</h2>
                <p className="text-gray-600">
                  {etablissement.moyenneNote}/5 ⭐ ({etablissement.nombreAvis}{" "}
                  avis)
                </p>
              </li>
            ))}
          </ul>

          {paginatedResults.length < results.length && (
            <button
              onClick={() => setCurrentPage((prev) => prev + 1)}
              className="mt-6 bg-bleu text-white px-4 py-2 mb-6 rounded"
            >
              Voir plus
            </button>
          )}
        </>
      )}

      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
          onClick={() => setSelectedImage(null)}
        >
          <img
            src={selectedImage}
            alt="Agrandissement"
            className="max-w-full max-h-full rounded-lg"
          />
        </div>
      )}
    </div>
  );
};

export default SearchResultsPage;
