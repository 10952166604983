import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import {
  doc,
  query,
  where,
  getDoc,
  getDocs,
  updateDoc,
  arrayUnion,
  addDoc,
  setDoc,
  collection,
} from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase"; // Import Firebase Authentication

const EtablissementPage = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const [etablissement, setEtablissement] = useState(null);
  const [etablissementId, setEtablissementId] = useState(null);
  const [auteur, setAuteur] = useState("");
  const [commentaire, setCommentaire] = useState("");
  const [note, setNote] = useState(1);
  const [email, setEmail] = useState("");
  const [createAccount, setCreateAccount] = useState(false); // State for creating account
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const maxCommentLength = 600; // Max characters for the comment

  useEffect(() => {
    if (createAccount && auteur) {
      setUsername(auteur);
    }
  }, [createAccount, auteur]);

  // Fetch establishment data
  useEffect(() => {
    const fetchEtablissement = async () => {
      try {
        const q = query(
          collection(db, "etablissements"),
          where("code_uniq_une_fois", "array-contains", code)
        );
        const q2 = query(
          collection(db, "etablissements"),
          where("code_unique", "array-contains", code)
        );

        let querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          querySnapshot = await getDocs(q2);
        }

        if (!querySnapshot.empty) {
          const etablissementDoc = querySnapshot.docs[0];
          setEtablissement(etablissementDoc.data());
          setEtablissementId(etablissementDoc.id);
        } else {
          console.error("Aucun établissement trouvé pour ce code.");
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de l'établissement",
          error
        );
      }
    };
    fetchEtablissement();
  }, [code]);

  // Email validation
  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  // Password validation
  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  // Submit review
  const handleSubmitAvis = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      alert("Veuillez entrer un email valide.");
      return;
    }
    if (createAccount && !validatePassword(password)) {
      setPasswordError(
        "Le mot de passe doit contenir au moins 8 caractères, une lettre et un chiffre."
      );
      return;
    }
    setIsSubmitting(true); // Start loading state
    try {
      const avisRef = await addDoc(collection(db, "avis"), {
        auteur,
        commentaire,
        note,
        email,
        date: new Date(),
        id_etablissement: etablissementId,
      });

      if (createAccount && username && password) {
        const emailExists = await checkEmailExists(email);

        if (emailExists) {
          alert("Cet email est déjà utilisé.");
          setIsSubmitting(false); // Stop loading
          return;
        }

        await createUserWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            const user = userCredential.user;
            await setDoc(doc(db, "users", user.uid), {
              email: user.email,
              pseudo: username,
              role: "user",
              cadeau: 0,
              date_creation: new Date(),
              uid: user.uid,
            });
          })
          .catch((error) => {
            console.error("Erreur lors de la création de l'utilisateur", error);
            setIsSubmitting(false); // Stop loading
          });
      }

      const etablissementRef = doc(db, "etablissements", etablissementId);
      const etablissementDoc = await getDoc(etablissementRef);

      if (etablissementDoc.exists()) {
        const etablissementData = etablissementDoc.data();
        const totalAvis = etablissementData.nombreAvis || 0;
        const totalNotes = (etablissementData.moyenneNote || 0) * totalAvis;
        const nouvelleNombreAvis = totalAvis + 1;
        const nouvelleMoyenneNote = (totalNotes + note) / nouvelleNombreAvis;

        await updateDoc(etablissementRef, {
          avis: arrayUnion(avisRef.id),
          nombreAvis: nouvelleNombreAvis,
          moyenneNote: parseFloat(nouvelleMoyenneNote.toFixed(1)),
        });

        const response = await fetch(
          "https://us-central1-avis-reel.cloudfunctions.net/sendThankYouEmail",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: email,
              nom: etablissement.nom,
              ville: etablissement.ville,
              emailGerant: etablissementData.email,
              reduction: etablissementData.reduction,
              avisAuteur: auteur,
              avisCommentaire: commentaire,
            }),
          }
        );

        if (!response.ok) {
          console.error("Erreur lors de l'envoi de l'e-mail de remerciement.");
        }

        setAuteur("");
        setCommentaire("");
        setNote(1);
        setEmail("");
        setUsername("");
        setPassword("");
        setCreateAccount(false);

        navigate(`/confirmation`, {
          state: { etablissementId: etablissementId },
        });
      }
    } catch (error) {
      console.error("Erreur lors de la soumission de l'avis", error);
      setIsSubmitting(false); // Stop loading
    }
  };

  // Check if email exists
  const checkEmailExists = async (email) => {
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  if (!etablissement) {
    return (
      <div>
        <p>Chargement...</p>
      </div>
    );
  }

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg max-w-4xl mx-auto mt-12 mb-6">
      <h1 className="text-2xl font-bold text-center">{etablissement.nom}</h1>
      <p className="text-lg text-center">{etablissement.ville}</p>

      <h2 className="text-xl font-semibold mt-6 mb-4">Donnez votre avis :</h2>
      <form onSubmit={handleSubmitAvis} className="space-y-6">
        <div className="flex flex-col">
          <label htmlFor="auteur" className="text-lg font-medium">
            Votre nom
          </label>
          <input
            type="text"
            id="auteur"
            value={auteur}
            onChange={(e) => setAuteur(e.target.value)}
            className="p-3 rounded-lg border border-gray-300"
            placeholder="Entrez votre nom"
            required
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="commentaire" className="text-lg font-medium">
            Votre commentaire
          </label>
          <textarea
            id="commentaire"
            value={commentaire}
            onChange={(e) => setCommentaire(e.target.value)}
            className="p-3 rounded-lg border border-gray-300 h-40"
            placeholder="Entrez votre commentaire"
            maxLength={maxCommentLength}
            required
          />
          <p className="text-sm text-gray-600 mt-1">
            {maxCommentLength - commentaire.length} caractères restants
          </p>
        </div>

        <div className="flex flex-col">
          <label htmlFor="note" className="text-lg font-medium">
            Votre note
          </label>
          <select
            id="note"
            value={note}
            onChange={(e) => setNote(Number(e.target.value))}
            className="p-3 rounded-lg border border-gray-300"
            required
          >
            <option value="">Choisir une note</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>

        <div className="flex flex-col">
          <label htmlFor="email" className="text-lg font-medium">
            Votre adresse e-mail
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="p-3 rounded-lg border border-gray-300"
            placeholder="Entrez votre e-mail"
            required
          />
        </div>

        <div className="flex items-center space-x-2 mt-4">
          <input
            type="checkbox"
            checked={createAccount}
            onChange={() => setCreateAccount(!createAccount)}
            id="createAccount"
          />
          <label htmlFor="createAccount" className="text-lg font-medium">
            Créer un compte
          </label>
        </div>

        {createAccount && (
          <div>
            <div className="flex flex-col mt-4">
              <label htmlFor="username" className="text-lg font-medium">
                Nom d'utilisateur
              </label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="p-3 rounded-lg border border-gray-300"
                placeholder="Nom d'utilisateur"
                required
              />
            </div>

            <div className="flex flex-col mt-4">
              <label htmlFor="password" className="text-lg font-medium">
                Mot de passe
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError(""); // Clear error on input change
                  }}
                  className="p-3 rounded-lg border border-gray-300 w-full"
                  placeholder="Mot de passe"
                  required
                />
                <button
                  type="button"
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? "Masquer" : "Afficher"}
                </button>
              </div>
              {passwordError && (
                <p className="text-red-500 text-sm mt-1">{passwordError}</p>
              )}
            </div>
          </div>
        )}

        <button
          type="submit"
          className={`mt-6 px-6 py-3 rounded-lg bg-bleu text-white font-bold w-full ${
            isSubmitting ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <span className="animate-spin">🌀</span> // Add loading spinner emoji here
          ) : (
            "Soumettre votre avis"
          )}
        </button>
      </form>
    </div>
  );
};

export default EtablissementPage;
