import React from "react";
import { Link } from "react-router-dom";

const HowItWorks = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-2xl shadow-lg">
      <h2 className="text-3xl font-bold text-center text-green-600 mb-6">
        Comment ça fonctionne ?
      </h2>
      <hr />
      <p className="text-md text-gray-700 text-center italic m-6">
        <a
          href="https://www.avisreel.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          AvisReel.com
        </a>{" "}
        permet de répondre à une problématique que rencontre les différents
        acteurs du recueil d'avis qui est la véracité et la pertinence d'un
        avis. Grâce à un code unique remis au client pour laisser un avis, nous
        assurons que les avis seront vrais.
      </p>
      <hr />

      <div className="space-y-8">
        {/* Section Clients */}
        <div>
          <h3 className="text-2xl font-semibold text-blue-600 mb-3">
            🔹 Pour les clients
          </h3>
          <ul className="list-disc list-inside space-y-2 text-gray-700">
            <li>
              <strong>1️⃣ Coupons </strong> – Récupérez votre coupon avec code
              unique ou QR code dans votre établissement.
            </li>
            <li>
              <strong>2️⃣ Code unique ou QR code </strong> – Scannez votre QR
              code ou saisissez le code unique sur{" "}
              <a
                href="https://www.avisreel.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                www.avisreel.com
              </a>
              .
            </li>
            <li>
              <strong>3️⃣ Laissez votre avis </strong> – Rédigez un avis et
              attribuez une note. La géolocalisation valide votre présence.
            </li>
            <li>
              <strong>4️⃣ Cadeau(x) </strong> – Vous recevez votre premier cadeau
              suite à votre avis déposé et rejoignez notre programme de fidélité
              pour gagner encore plus de cadeaux.
            </li>
            <li>
              <strong>5️⃣ Partage </strong> – Votre avis est en ligne sur{" "}
              <a
                href="https://www.avisreel.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                www.avisreel.com
              </a>
              . et aide la communauté à faire le meilleur choix.
            </li>
          </ul>
        </div>

        {/* Section Établissements */}
        <div>
          <h3 className="text-2xl font-semibold text-blue-600 mb-3">
            🔹 Pour les établissements
          </h3>
          <ul className="list-disc list-inside space-y-2 text-gray-700">
            <li>
              <strong>✅ Inscription & Validation</strong> – Créez votre compte
              et soumettez votre établissement pour vérification.
            </li>
            <li>
              <strong>✅ Recevez vos QR codes & coupons</strong> – Utilisez nos
              supports pour collecter vos avis réels.
            </li>
            <li>
              <strong>✅ Gérez vos avis</strong> – Consultez et répondez aux
              avis clients en toute transparence.
            </li>
            <li>
              <strong>✅ Augmentez votre visibilité </strong> – Plus d’avis
              fiables = plus de clients !
            </li>
          </ul>
        </div>

        {/* Call to Action */}
        <div className="text-center mt-6">
          <p className="text-lg font-medium text-gray-700">
            🎯 AvisRéel garantit des avis réels grâce à un système de recueil
            innovant !
          </p>
          <Link
            to="/registerEstablishment"
            className="inline-block mt-4 px-6 py-3 bg-green-600 text-white font-semibold rounded-lg shadow-md hover:bg-green-700 transition"
          >
            Inscrivez votre établissement maintenant !
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
