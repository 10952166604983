import React, { useState, useEffect, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import logo from "../../assets/logo512.png";
import bgImage1 from "../../assets/img_bgHeader1.png";
import bgImage2 from "../../assets/img_bgHeader2.png";
import bgImage3 from "../../assets/img_bgHeader3.png";
import bgImage4 from "../../assets/img_bgHeader4.png";
import bgImage5 from "../../assets/img_bgHeader5.png";
import bgImage6 from "../../assets/img_bgHeader6.png";
import bgImage7 from "../../assets/img_bgHeader7.png";
import menuIcon from "../../assets/menuIcon.png";
import { signOut } from "firebase/auth";
import { auth, db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore"; // Import pour récupérer le rôle de l'utilisateur

const Header = ({
  onNavigateContact,
  onNavigateHome,
  onNavigateRegisterEstablishment,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, loading] = useAuthState(auth); // Utilisation de useAuthState
  const [userRole, setUserRole] = useState(null); // Variable pour stocker le rôle de l'utilisateur
  const menuRef = useRef(null); // Référence pour l'élément menu

  // Fonction pour récupérer le rôle de l'utilisateur dans Firestore
  const fetchUserRole = async () => {
    if (user) {
      try {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const role = userDoc.data().role;
          setUserRole(userDoc.data().role); // On récupère le rôle et on le stocke
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération du rôle utilisateur : ",
          error
        );
      }
    }
  };

  // Appel de la fonction pour récupérer le rôle dès que l'utilisateur se connecte
  useEffect(() => {
    fetchUserRole();
  }, [user]); // On relance quand l'utilisateur change

  // Fonction de déconnexion
  const handleLogout = async () => {
    try {
      await signOut(auth);
      alert("Vous êtes déconnecté !");
    } catch (error) {
      console.error(error);
    }
  };

  // Fermeture du menu lorsque l'utilisateur clique à l'extérieur
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false); // Fermer le menu
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getBackgroundImage = () => {
    const width = window.innerWidth;
    if (width < 416) return bgImage6;
    if (width < 584) return bgImage7;
    if (width < 670) return bgImage5;
    if (width < 928) return bgImage5;
    if (width < 1162) return bgImage2;
    if (width < 1552) return bgImage3;
    return bgImage4;
  };

  const handleMenuClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className="relative w-full h-60">
      <div
        className="absolute top-0 left-0 w-full h-1/2 bg-cover bg-center"
        style={{
          backgroundImage: `url(${getBackgroundImage()})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>

      <div className="container mx-auto h-full px-4 flex justify-between relative">
        <div className="flex-shrink-0">
          <Link to="/">
            <img src={logo} alt="Logo" className="h-48 w-auto object-contain" />
          </Link>
        </div>

        <div className="flex flex-col justify-start items-start ml-2 flex-grow">
          <h1 className="text-right font-lobster flex flex-col sm:flex-row sm:items-center">
            <span className="text-vert text-sm sm:text-xl md:text-xl lg:text-xl font-bold mr-1">
              1er Site de France avec des
            </span>
            <span className="text-white text-sm sm:text-xl md:text-xl lg:text-xl font-bold">
              Avis Garantis 100% Réels
            </span>
          </h1>

          <nav
            className={`mt-4 ${
              isMenuOpen ? "block" : "hidden"
            } md:flex md:items-center md:justify-between md:w-full`}
            style={{ zIndex: 50 }}
            ref={menuRef}
          >
            <ul className="flex-col space-y-2 bg-bleu p-4 md:flex md:flex-row md:space-y-0 md:space-x-4">
              <li className="flex items-center md:inline-block">
                <img
                  src={menuIcon}
                  alt="Icon"
                  className="w-4 h-4 mr-2 hidden md:inline-block"
                />
                <Link
                  to="/"
                  className="text-white hover:text-vert px-2 py-1 rounded transition-colors duration-300"
                  onClick={handleMenuClick}
                >
                  Accueil
                </Link>
              </li>
              <li className="flex items-center md:inline-block">
                <img
                  src={menuIcon}
                  alt="Icon"
                  className="w-4 h-4 mr-2 hidden md:inline-block"
                />
                <Link
                  to="/leaderboard"
                  className="text-white hover:text-vert px-2 py-1 rounded transition-colors duration-300"
                  onClick={handleMenuClick}
                >
                  Classements
                </Link>
              </li>

              <li className="flex items-center md:inline-block">
                <img
                  src={menuIcon}
                  alt="Icon"
                  className="w-4 h-4 mr-2 hidden md:inline-block"
                />
                <Link
                  to="/contact"
                  className="text-white hover:text-vert px-2 py-1 rounded transition-colors duration-300"
                  onClick={handleMenuClick}
                >
                  Contact
                </Link>
              </li>
              <li className="flex items-center md:inline-block">
                <img
                  src={menuIcon}
                  alt="Icon"
                  className="w-4 h-4 mr-2 hidden md:inline-block"
                />
                <Link
                  to="/registerEstablishment"
                  className="text-white hover:text-vert px-2 py-1 rounded transition-colors duration-300"
                  onClick={handleMenuClick}
                >
                  Inscrire Mon Etablissement
                </Link>
              </li>
              {user && (
                <li className="flex items-center md:inline-block">
                  <img
                    src={menuIcon}
                    alt="Icon"
                    className="w-4 h-4 mr-2 hidden md:inline-block"
                  />
                  <Link
                    to="/howItWorks"
                    className="text-white hover:text-vert px-2 py-1 rounded transition-colors duration-300"
                    onClick={handleMenuClick}
                  >
                    Comment ça fonctionne?
                  </Link>
                </li>
              )}

              {/* Menu utilisateur */}
              {user && (
                <>
                  <li className="flex md:hidden items-center md:inline-block">
                    <Link
                      to={
                        userRole === "admin" ? "/adminDashboard" : "/dashboard"
                      }
                      className="text-white hover:text-vert px-2 py-1 rounded transition-colors duration-300"
                      onClick={handleMenuClick}
                    >
                      Mon compte
                    </Link>
                  </li>
                  <li className="flex md:hidden items-center md:inline-block">
                    <button
                      onClick={handleLogout}
                      className="text-white hover:text-vert px-2 py-1 rounded transition-colors duration-300"
                    >
                      Se déconnecter
                    </button>
                  </li>
                </>
              )}

              {/* Si l'utilisateur n'est pas connecté */}
              {!user && (
                <li className="flex md:hidden items-center md:inline-block">
                  <Link
                    to="/login"
                    className="text-white hover:text-vert px-2 py-1 rounded transition-colors duration-300"
                    onClick={handleMenuClick}
                  >
                    Se connecter
                  </Link>
                </li>
              )}
            </ul>
          </nav>
        </div>

        {/* Bouton hamburger */}
        <div className="absolute top-0 right-0 mt-10 mr-4 z-50">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden text-white px-4 py-2 rounded"
          >
            <span className="block relative">
              {isMenuOpen ? (
                <span className="absolute right-0 top-0 text-3xl text-white">
                  ✕
                </span>
              ) : (
                <div className="flex flex-col justify-between h-6">
                  <span className="block w-8 h-1 bg-white"></span>
                  <span className="block w-8 h-1 bg-white"></span>
                  <span className="block w-8 h-1 bg-white"></span>
                </div>
              )}
            </span>
          </button>
        </div>

        {/* Menu de connexion/déconnexion sur grands écrans */}
        <div className="hidden md:flex items-center space-x-4 mt-6">
          {user ? (
            <>
              <Link
                to={userRole === "admin" ? "/adminDashboard" : "/dashboard"}
                className="text-white bg-bleu hover:text-vert px-2 py-1 rounded transition-colors duration-300"
              >
                Mon compte
              </Link>
              <button
                onClick={handleLogout}
                className="text-white bg-bleu hover:text-vert px-2 py-1 rounded transition-colors duration-300"
              >
                Se déconnecter
              </button>
            </>
          ) : (
            <Link
              to="/login"
              className="text-white bg-bleu hover:text-vert px-2 py-1 rounded transition-colors duration-300"
            >
              Se connecter
            </Link>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
