import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom"; // Importation du hook de navigation

const GeoValidationPage = () => {
  const [userCoords, setUserCoords] = useState(null);
  const [distance, setDistance] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState(null);
  const [code, setCode] = useState("");
  const [etablissementCoords, setEtablissementCoords] = useState(null);
  const maxDistance = 300; // Distance maximale en mètres
  const navigate = useNavigate(); // Hook pour la redirection

  // Fonction pour calculer la distance entre deux points géographiques
  const calculateDistance = (lat1, lng1, lat2, lng2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371e3; // Rayon de la Terre en mètres
    const φ1 = toRad(lat1);
    const φ2 = toRad(lat2);
    const Δφ = toRad(lat2 - lat1);
    const Δλ = toRad(lng2 - lng1);

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance en mètres
  };

  // Récupérer les coordonnées de l'établissement et les données de l'utilisateur
  useEffect(() => {
    const fetchEtablissementCoords = async () => {
      try {
        // Utilisation de window.location.hash pour récupérer le code
        const search = window.location.search; // Récupérer la query string
        const urlParams = new URLSearchParams(search);
        const scannedCode = urlParams.get("code"); // Récupérer le paramètre "code"

        console.log("Code scanné:", scannedCode);

        if (scannedCode) {
          setCode(scannedCode);

          // Rechercher l'établissement correspondant dans Firebase
          const q = query(
            collection(db, "etablissements"),
            where("code_unique", "array-contains", scannedCode)
          );
          const querySnapshot = await getDocs(q);
          console.log("Query result:", querySnapshot.docs);

          if (!querySnapshot.empty) {
            const etablissement = querySnapshot.docs[0].data();
            console.log(etablissement);

            setEtablissementCoords({
              lat: etablissement.coord._lat, // Accède à la latitude
              lng: etablissement.coord._long, // Accède à la longitude
            });

            // Suivi de la géolocalisation ici...
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  const { latitude, longitude } = position.coords;
                  setUserCoords({ lat: latitude, lng: longitude });

                  // Calculer la distance
                  const dist = calculateDistance(
                    latitude,
                    longitude,
                    etablissement.coord._lat,
                    etablissement.coord._long
                  );
                  console.log("Distance calculée:", dist);

                  setDistance(dist);
                  setIsValid(dist <= maxDistance);
                },
                (err) => setError("Géolocalisation refusée ou indisponible.")
              );
            } else {
              setError(
                "La géolocalisation n'est pas supportée par votre navigateur."
              );
            }
          } else {
            setError("Établissement non trouvé.");
          }
        } else {
          setError("Code manquant dans l'URL.");
        }
      } catch (err) {
        console.error("Erreur lors de la récupération des données : ", err);
        setError("Erreur lors de la récupération des données.");
      }
    };

    fetchEtablissementCoords();
  }, []);

  useEffect(() => {
    if (isValid) {
      // Rediriger vers la page d'avis lorsque la géolocalisation est validée
      navigate(`/etablissement/${code}`); // Remplace par l'ID réel de l'établissement si nécessaire
    }
  }, [isValid, code, navigate]);

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg max-w-4xl mx-auto mt-12">
      {error && <p className="text-red-600">{error}</p>}

      {userCoords && etablissementCoords ? (
        <>
          <h1 className="text-2xl font-bold">
            Validation de l'accès à l'établissement
          </h1>
          <p>Code scanné : {code}</p>
          <p>
            Distance : {distance ? `${distance.toFixed(2)} m` : "Calcul..."}
          </p>
          {isValid ? (
            <p className="text-green-600 font-bold mt-4">
              Vous êtes à moins de 100 mètres de l'établissement. Accès validé !
            </p>
          ) : (
            <p className="text-red-600 font-bold mt-4">
              Vous êtes trop éloigné de l'établissement.
            </p>
          )}
        </>
      ) : (
        <p>Chargement de vos coordonnées...</p>
      )}
    </div>
  );
};

export default GeoValidationPage;
