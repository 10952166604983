import React, { useState, useEffect } from "react";
import { Route, Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

const PrivateRoute = ({ children, requiredRole }) => {
  const [user, loading] = useAuthState(auth);
  const [userRole, setUserRole] = useState(null);
  const [roleLoading, setRoleLoading] = useState(true); // Ajout de l'état de chargement du rôle

  useEffect(() => {
    const fetchUserRole = async () => {
      if (user) {
        try {
          const userDocRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setUserRole(userDoc.data().role); // Récupère le rôle de l'utilisateur
            setRoleLoading(false); // Indique que le rôle est chargé
          }
        } catch (error) {
          console.error(
            "Erreur lors de la récupération du rôle utilisateur : ",
            error
          );
          setRoleLoading(false); // Assure que le rôle a été chargé même en cas d'erreur
        }
      }
    };
    fetchUserRole();
  }, [user]);

  if (loading || roleLoading) {
    return <p>Chargement...</p>; // Attend le chargement des deux : utilisateur et rôle
  }

  if (!user) {
    return <Navigate to="/login" />; // Si l'utilisateur n'est pas connecté, rediriger vers /login
  }

  if (requiredRole && userRole !== requiredRole) {
    // Si l'utilisateur n'a pas le rôle requis, rediriger vers la page d'accueil
    return <Navigate to="/" />;
  }

  return children; // Si tout est bon, rendre les enfants
};

export default PrivateRoute;
