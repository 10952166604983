import React, { useState } from "react";

const validateForm = (formData) => {
  const errors = {};

  // Validation pour le code postal (doit être numérique)
  if (!formData.codePostal || !/^\d{5}$/.test(formData.codePostal)) {
    errors.codePostal = "Le code postal doit être un nombre de 5 chiffres.";
  }

  // Validation pour le téléphone (doit suivre le format d'un numéro français)
  if (
    !formData.telephone ||
    !/^(\+33|0)[1-9](\d{2}){4}$/.test(formData.telephone)
  ) {
    errors.telephone = "Le numéro de téléphone est invalide.";
  }

  // Validation de l'email
  if (
    !formData.email ||
    !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)
  ) {
    errors.email = "L'email est invalide.";
  }

  // Validation du champ "demande" (ne doit pas être vide)
  if (!formData.demande) {
    errors.demande = "La demande est obligatoire.";
  }

  return errors;
};

const Contact = () => {
  const [formData, setFormData] = useState({
    civilite: "",
    nomPatron: "",
    prenom: "",
    codePostal: "",
    ville: "",
    telephone: "",
    email: "",
    commentConnu: "",
    demande: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false); // Gestion du spinner
  const [isSuccess, setIsSuccess] = useState(false); // Gestion du pop-up

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Empêche de cliquer plusieurs fois

    // Validation des données
    const formErrors = validateForm(formData);
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch(
        "https://us-central1-avis-reel.cloudfunctions.net/sendContactEmail", // URL de la fonction Firebase
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData), // Envoi des données du formulaire
        }
      );

      const result = await response.text(); // Utiliser .text() pour récupérer la réponse en texte

      if (response.ok) {
        // Si la réponse est correcte (statut HTTP 2xx), afficher le résultat
        console.log(result); // Afficher la réponse en cas de succès
        setIsSuccess(true); // Afficher le pop-up de succès
        setFormData({
          civilite: "",
          nomPatron: "",
          prenom: "",
          codePostal: "",
          ville: "",
          telephone: "",
          email: "",
          commentConnu: "",
          demande: "",
        });
        setErrors({}); // Réinitialiser les erreurs
      } else {
        throw new Error(result || "Erreur lors de l'envoi");
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi du message :", error);
      alert("Une erreur est survenue, veuillez réessayer.");
    } finally {
      setIsSubmitting(false); // Réactive le bouton
    }
  };

  return (
    <div className="flex flex-col items-center gap-4 p-6 mx-auto max-w-3xl">
      {/* Titre principal */}
      <h1 className="text-4xl font-lobster font-bold text-center text-black mb-4">
        Contactez-nous
      </h1>

      {/* Sous-titre */}
      <h2 className="text-2xl font-lobster font-bold text-black mb-4">
        Formulaire de contact
      </h2>

      {/* Message d'instructions */}
      <div className="w-full p-4 bg-vert rounded-lg text-center text-black mb-6">
        Merci de bien vouloir remplir ce formulaire. Il nous permet de vous
        connaître et de vous répondre dans les plus brefs délais. <br />
        <span className="text-red-600 font-bold">*</span> Les champs marqués
        sont obligatoires.
      </div>

      {/* Pop-up de confirmation */}
      {isSuccess && (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          onClick={() => setIsSuccess(false)} // Ferme la pop-up en cliquant
        >
          <div className="p-6 bg-white rounded-lg text-center">
            <p className="text-lg font-bold text-vert mb-4">
              Votre message a été envoyé avec succès !
            </p>
            <button
              className="p-2 bg-vert text-white rounded-lg"
              onClick={() => setIsSuccess(false)}
            >
              Fermer
            </button>
          </div>
        </div>
      )}

      {/* Formulaire */}
      <form
        className="w-full p-6 bg-bleu rounded-lg space-y-4 text-black"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col">
          <label>Civilité</label>
          <select
            className="p-2 rounded"
            name="civilite"
            value={formData.civilite}
            onChange={handleChange}
          >
            <option value="">Choisir</option>
            <option value="Monsieur">Monsieur</option>
            <option value="Madame">Madame</option>
            <option value="Mademoiselle">Mademoiselle</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label>Nom</label>
          <input
            type="text"
            className="p-2 rounded"
            name="nomPatron"
            value={formData.nomPatron}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col">
          <label>Prénom</label>
          <input
            type="text"
            className="p-2 rounded"
            name="prenom"
            value={formData.prenom}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col">
          <label>
            Code postal<span className="text-red-600 font-bold">*</span>
          </label>
          <input
            type="text"
            className="p-2 rounded"
            name="codePostal"
            required
            value={formData.codePostal}
            onChange={handleChange}
          />
          {errors.codePostal && (
            <p className="text-red-600 text-xs">{errors.codePostal}</p>
          )}
        </div>
        <div className="flex flex-col">
          <label>
            Ville<span className="text-red-600 font-bold">*</span>
          </label>
          <input
            type="text"
            className="p-2 rounded"
            name="ville"
            required
            value={formData.ville}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col">
          <label>
            Téléphone<span className="text-red-600 font-bold">*</span>
          </label>
          <input
            type="tel"
            className="p-2 rounded"
            name="telephone"
            required
            value={formData.telephone}
            onChange={handleChange}
          />
          {errors.telephone && (
            <p className="text-red-600 text-xs">{errors.telephone}</p>
          )}
        </div>
        <div className="flex flex-col">
          <label>
            Email<span className="text-red-600 font-bold">*</span>
          </label>
          <input
            type="email"
            className="p-2 rounded"
            name="email"
            required
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && (
            <p className="text-red-600 text-xs">{errors.email}</p>
          )}
        </div>
        <div className="flex flex-col">
          <label>
            Comment nous avez-vous connu?{" "}
            <span className="text-red-600 font-bold">*</span>
          </label>
          <input
            type="text"
            className="p-2 rounded"
            name="commentConnu"
            required
            value={formData.commentConnu}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col">
          <label>
            Votre demande<span className="text-red-600 font-bold">*</span>
          </label>
          <textarea
            className="p-2 rounded"
            name="demande"
            required
            value={formData.demande}
            onChange={handleChange}
          />
          {errors.demande && (
            <p className="text-red-600 text-xs">{errors.demande}</p>
          )}
        </div>
        <div className="flex items-center">
          <input type="checkbox" className="mr-2" required />
          <label>
            J'accepte l'utilisation de mes données personnelles à des fins de
            validation et de suivi de mon inscription.
            <span className="text-red-600 font-bold">*</span>
          </label>
        </div>
        <div className="flex items-center">
          <input type="checkbox" className="mr-2" required />
          <label>
            J'accepte l'utilisation de mes données personnelles afin de recevoir
            vos actualités.<span className="text-red-600 font-bold">*</span>
          </label>
        </div>
        <button
          type="submit"
          className={`w-full p-2 rounded-lg font-bold text-white ${
            isSubmitting ? "bg-gray-400 cursor-not-allowed" : "bg-vert"
          }`}
          disabled={isSubmitting} // Désactive le bouton pendant l'envoi
        >
          {isSubmitting ? (
            <div className="flex items-center justify-center">
              <svg
                className="animate-spin h-5 w-5 mr-2 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Envoi...
            </div>
          ) : (
            "Valider"
          )}
        </button>
      </form>

      {/* Message de confidentialité */}
      <p className="text-xs mt-4 text-center">
        Vos données sont collectées pour prendre en compte votre demande et y
        répondre. Vous disposez d'un droit d'accès, de modification, de
        rectification et de suppression des données qui vous concernent (Décret
        n° 2019-536 du 30 mai 2019 - loi "Informatique et Liberté"). Pour
        l'exercer, adressez-vous à Avis Réel. Notre société est seule
        destinataire des informations que vous lui communiquez.
      </p>
    </div>
  );
};

export default Contact;
