import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import {
  getFirestore,
  collection,
  query,
  where,
  doc,
  getDoc,
  orderBy,
  limit,
  startAfter,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import EtablissementDashboard from "./EtablissementDashboard";

const Dashboard = () => {
  const [user, loading] = useAuthState(auth);
  const [pseudo, setPseudo] = useState("");
  const [reviews, setReviews] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [totalReviews, setTotalReviews] = useState(0);
  const [rewardAchieved, setRewardAchieved] = useState(false);
  const [rewardClaimedCount, setRewardClaimedCount] = useState(0); // Nombre de fois que la récompense a été réclamée
  const db = getFirestore();
  const PAGE_SIZE = 5;
  const TARGET_REVIEWS = 10; // Nombre d'avis nécessaires pour débloquer la récompense
  const [userEtablissement, setUserEtablissement] = useState(null);
  const [etablissements, setEtablissements] = useState([]);
  const [activeTab, setActiveTab] = useState("reviews");

  useEffect(() => {
    if (user) {
      const checkUserEtablissement = async () => {
        const etablissementsRef = collection(db, "etablissements");
        const q = query(etablissementsRef, where("email", "==", user.email));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const etablissementData = querySnapshot.docs[0].data();
          setUserEtablissement({
            id: querySnapshot.docs[0].id,
            ...etablissementData,
          });
        } else {
          setUserEtablissement(null); // Aucun établissement trouvé
        }
      };

      checkUserEtablissement();
    }
  }, [user, db]);

  useEffect(() => {
    if (user) {
      const fetchUserEtablissements = async () => {
        try {
          const etablissementsRef = collection(db, "etablissements");
          const q = query(etablissementsRef, where("email", "==", user.email));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const userEtablissements = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setEtablissements(userEtablissements);
          } else {
            setEtablissements([]);
          }
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des établissements :",
            error
          );
        }
      };

      fetchUserEtablissements();
    }
  }, [user, db]);

  useEffect(() => {
    if (user) {
      const fetchPseudo = async () => {
        const userDoc = doc(db, "users", user.uid);
        const userSnap = await getDoc(userDoc);

        if (userSnap.exists()) {
          setPseudo(userSnap.data().pseudo || "Utilisateur");
          setRewardClaimedCount(userSnap.data().cadeau || 0); // Récupérer le nombre de cadeaux réclamés
        }
      };

      fetchPseudo();
    }
  }, [user, db]);

  useEffect(() => {
    if (user) {
      const fetchReviews = async () => {
        // Récupérer les avis paginés
        const reviewsRef = collection(db, "avis");
        const q = query(
          reviewsRef,
          where("email", "==", user.email),
          orderBy("date", "desc"),
          limit(PAGE_SIZE)
        );
        const querySnapshot = await getDocs(q);

        const fetchedReviews = [];
        for (let docSnap of querySnapshot.docs) {
          const reviewData = docSnap.data();

          const etablissementRef = doc(
            db,
            "etablissements",
            reviewData.id_etablissement
          );
          const etablissementSnap = await getDoc(etablissementRef);

          if (etablissementSnap.exists()) {
            const etablissementData = etablissementSnap.data();
            reviewData.etablissementNom = etablissementData.nom;
            reviewData.etablissementVille = etablissementData.ville;
            reviewData.etablissementPhotoURL = etablissementData.photoURL;
          }

          fetchedReviews.push({ id: docSnap.id, ...reviewData });
        }

        setReviews(fetchedReviews);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);

        // Récupérer le nombre total d'avis de l'utilisateur
        const totalQuery = query(reviewsRef, where("email", "==", user.email));
        const totalSnapshot = await getDocs(totalQuery);
        const newTotalReviews = totalSnapshot.size; // Total d'avis trouvés
        setTotalReviews(newTotalReviews); // Recharger le nombre total d'avis

        // Calculer la progression vers la récompense en tenant compte des cadeaux réclamés
        const adjustedTotalReviews = newTotalReviews - rewardClaimedCount * 10;
        setRewardAchieved(adjustedTotalReviews >= TARGET_REVIEWS); // Vérifier si la récompense est atteinte
      };

      fetchReviews();
    }
  }, [user, db, rewardClaimedCount]);

  const loadMoreReviews = async () => {
    if (!lastVisible) return;

    const reviewsRef = collection(db, "avis");
    const q = query(
      reviewsRef,
      where("email", "==", user.email),
      orderBy("date", "desc"),
      startAfter(lastVisible),
      limit(PAGE_SIZE)
    );
    const querySnapshot = await getDocs(q);

    const fetchedReviews = [];
    for (let docSnap of querySnapshot.docs) {
      const reviewData = docSnap.data();

      const etablissementRef = doc(
        db,
        "etablissements",
        reviewData.id_etablissement
      );
      const etablissementSnap = await getDoc(etablissementRef);

      if (etablissementSnap.exists()) {
        const etablissementData = etablissementSnap.data();
        reviewData.etablissementNom = etablissementData.nom;
        reviewData.etablissementVille = etablissementData.ville;
        reviewData.etablissementPhotoURL = etablissementData.photoURL;
      }

      fetchedReviews.push({ id: docSnap.id, ...reviewData });
    }

    setReviews((prevReviews) => [...prevReviews, ...fetchedReviews]);
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
  };

  const handleClaimReward = async () => {
    try {
      // Incrémenter le compteur de cadeaux réclamés dans la BDD
      const userDoc = doc(db, "users", user.uid);
      const newRewardClaimedCount = rewardClaimedCount + 1;
      await updateDoc(userDoc, {
        cadeau: newRewardClaimedCount, // Incrémenter de 1
      });

      // Mettre à jour localement
      setRewardClaimedCount(newRewardClaimedCount);

      // Recalculer la progression en tenant compte des cadeaux réclamés
      const adjustedTotalReviews = totalReviews - newRewardClaimedCount * 10;
      setRewardAchieved(adjustedTotalReviews >= TARGET_REVIEWS); // Vérifier si la récompense est atteinte

      // Envoyer une requête à la Cloud Function pour notifier par e-mail
      await fetch(
        "https://us-central1-avis-reel.cloudfunctions.net/sendRewardClaimEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: user.email,
            pseudo: pseudo,
            totalReviews: adjustedTotalReviews, // On envoie le total ajusté
            rewardCount: newRewardClaimedCount,
          }),
        }
      );
      alert("Récompense réclamée avec succès !");
    } catch (error) {
      console.error("Erreur lors de la réclamation de la récompense :", error);
    }
  };

  const progressPercentage = Math.min(
    ((totalReviews - rewardClaimedCount * 10) / TARGET_REVIEWS) * 100,
    100
  );

  if (loading) {
    return <p>Chargement...</p>;
  }

  if (!user) {
    return <p>Vous devez être connecté pour accéder au tableau de bord.</p>;
  }

  return (
    <div className="min-h-screen flex flex-col bg-gray-100 p-6">
      <div className="container mx-auto bg-white p-8 shadow rounded">
        <h2 className="text-3xl font-bold text-gray-800 mb-4">
          Bienvenue, {pseudo} !
        </h2>
        <p className="text-gray-600">
          Voici vos informations personnelles et vos avis déposés.
        </p>

        {/* Navigation entre les sections */}
        <div className="flex space-x-4 mt-6">
          <button
            className="text-bleu font-semibold hover:text-vert"
            onClick={() => setActiveTab("reviews")}
          >
            Mes Avis
          </button>
          <button
            className={`text-bleu font-semibold ${
              activeTab === "info" ? "underline" : ""
            } hover:text-vert`}
            onClick={() => setActiveTab("info")}
          >
            Mes Infos
          </button>
          {userEtablissement && (
            <button
              className="text-bleu font-semibold hover:text-vert"
              onClick={() => setActiveTab("etablissement")}
            >
              Mes Établissements
            </button>
          )}
        </div>
        {activeTab === "reviews" && (
          <div className="mt-6">
            {/* Section de Progression pour les cadeaux */}
            <div className="mb-8">
              <h3 className="text-2xl font-semibold text-gray-700 mb-2">
                Progression vers les Cadeaux
              </h3>
              <div className="relative w-full bg-gray-200 rounded h-4 mb-4">
                <div
                  className="bg-bleu h-4 rounded"
                  style={{ width: `${progressPercentage}%` }}
                ></div>
              </div>
              <p className="text-gray-500 mb-4">
                {totalReviews - rewardClaimedCount * 10}/{TARGET_REVIEWS} avis
                nécessaires pour débloquer une récompense
              </p>

              {rewardAchieved && !rewardClaimedCount && (
                <button
                  onClick={handleClaimReward}
                  className="mt-4 bg-green-500 text-white px-4 py-2 rounded shadow-sm hover:bg-green-600 transition duration-200"
                >
                  Réclamer la récompense
                </button>
              )}

              <div className="mt-4 mb-8">
                <h4 className="text-xl font-semibold">
                  Cadeaux réclamés : {rewardClaimedCount}
                </h4>
              </div>
            </div>

            <hr className="border-t-2 border-gray-300 mb-8" />

            {/* Section des Avis */}
            <h3 className="text-2xl font-semibold text-gray-700">Vos Avis</h3>
            <p className="text-gray-600 mb-4">Total d'avis : {totalReviews}</p>

            <ul className="flex flex-col gap-4">
              {reviews.map((review) => (
                <li
                  key={review.id}
                  className="p-4 bg-white shadow-md border-l-4 border-bleu rounded-lg hover:border-vert hover:bg-gray-100 cursor-pointer"
                  onClick={() =>
                    (window.location.href = `/etablissement/details/${review.id_etablissement}`)
                  }
                >
                  <div className="flex items-center space-x-4">
                    {review.etablissementPhotoURL && (
                      <img
                        src={review.etablissementPhotoURL}
                        alt={review.etablissementNom}
                        className="w-16 h-16 rounded-full object-cover"
                      />
                    )}
                    <div>
                      <h4 className="text-lg font-bold text-bleu hover:text-vert">
                        {review.etablissementNom} ({review.etablissementVille})
                      </h4>
                      <p className="text-gray-600">{review.commentaire}</p>
                      <p className="text-sm text-gray-500">
                        Note : {review.note}/5 -{" "}
                        {new Date(
                          review.date.seconds * 1000
                        ).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>

            {lastVisible && (
              <button
                onClick={loadMoreReviews}
                className="mt-4 bg-bleu text-white px-4 py-2 rounded shadow-sm hover:bg-vert transition duration-200"
              >
                Charger plus
              </button>
            )}
          </div>
        )}
        {activeTab === "info" && (
          <div className="mt-6">
            <h3 className="text-2xl font-semibold text-gray-700">Mes Infos</h3>
            <p className="text-gray-600 mt-4">
              <strong>Pseudo :</strong> {pseudo}
            </p>
            <p className="text-gray-600 mt-2">
              <strong>Email :</strong> {user.email}
            </p>
          </div>
        )}
        {activeTab === "etablissement" && (
          <div className="mt-6">
            <h3 className="text-2xl font-semibold text-gray-700 mb-4">
              Mes Établissements
            </h3>
            {etablissements.length > 0 ? (
              etablissements.map((etablissement) => (
                <div key={etablissement.id} className="mb-6">
                  <div className="border bg-gray-100 border-gray-300 rounded-lg p-6 mb-6 shadow-sm">
                    <EtablissementDashboard etablissement={etablissement} />
                  </div>
                </div>
              ))
            ) : (
              <p>Aucun établissement trouvé.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
