import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet"; // Importation de Leaflet

export default function Map({ adresse, ville, position }) {
  if (!position || position.length !== 2 || !position[0] || !position[1]) {
    return <div>Coordonnées invalides</div>; // Afficher un message si les coordonnées sont invalides
  }

  // Créez une icône personnalisée pour le marqueur
  const markerIcon = new L.Icon({
    iconUrl: require("../assets/marker.webp"), // Remplacez ce chemin par celui de votre icône
    iconSize: [40, 40], // Taille de l'icône
    iconAnchor: [12, 41], // Point d'ancrage de l'icône
    popupAnchor: [1, -34], // Position du popup par rapport à l'icône
  });

  return (
    <div className="map-container">
      <MapContainer
        center={position} // Utilise la position reçue comme prop
        zoom={15}
        style={{ width: "100%", height: "450px" }}
        scrollWheelZoom={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={position} icon={markerIcon}>
          {" "}
          {/* Appliquez l'icône personnalisée */}
          <Popup>{`${adresse}, ${ville}`}</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}
