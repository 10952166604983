import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import firebase from "firebase/compat/app"; // Import principal de Firebase
import "firebase/compat/firestore"; // Import de Firestore si vous l'utilisez
import { db, auth } from "../firebase";
import { getAuth } from "firebase/auth";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  addDoc,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { PhoneIcon } from "@heroicons/react/24/solid";
import Map from "../utils/map";

// Fonction de conversion des coordonnées
const convertCoordStringToArray = (coordString) => {
  const regex = /([\d.-]+)° ([NS]), ([\d.-]+)° ([EW])/;
  const match = coordString.match(regex);

  if (match) {
    const lat = parseFloat(match[1]);
    const lng = parseFloat(match[3]);

    // Appliquez le signe correct pour latitude et longitude
    const latitude = match[2] === "S" ? -lat : lat;
    const longitude = match[4] === "W" ? -lng : lng;

    return [latitude, longitude];
  } else {
    return null; // Si la chaîne n'est pas dans le bon format
  }
};

const EtablissementDetailPage = () => {
  const { id } = useParams();
  const [etablissement, setEtablissement] = useState(null);
  const [avis, setAvis] = useState([]);
  const [filteredAvis, setFilteredAvis] = useState([]);
  const [moyenneNote, setMoyenneNote] = useState(0);
  const [nombreAvis, setNombreAvis] = useState(0);
  const [filterStars, setFilterStars] = useState(null);
  const [starsCount, setStarsCount] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [position, setPosition] = useState([0, 0]);
  const [showMap, setShowMap] = useState(false);
  const user = auth.currentUser;
  const [userEmail, setUserEmail] = useState(null);
  const [responses, setResponses] = useState({});
  const [showResponseInput, setShowResponseInput] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  const toggleMapVisibility = () => {
    setShowMap((prev) => !prev);
  };

  useEffect(() => {
    if (user) {
      setUserEmail(user.email);
    }
  }, [user]);

  // Pagination state
  const [visibleAvisCount, setVisibleAvisCount] = useState(10);
  // State for "Voir plus" per avis
  const [expandedAvis, setExpandedAvis] = useState({});

  useEffect(() => {
    if (!id) {
      setError("L'ID de l'établissement est manquant.");
      setLoading(false);
      return;
    }

    const fetchEtablissement = async () => {
      setLoading(true);
      try {
        const etablissementRef = doc(db, "etablissements", id);
        const etablissementDoc = await getDoc(etablissementRef);

        if (etablissementDoc.exists()) {
          const etablissementData = etablissementDoc.data();
          setEtablissement(etablissementData);

          // Vérifiez si l'établissement a des coordonnées de type GeoPoint
          if (etablissementData.coord instanceof firebase.firestore.GeoPoint) {
            const latitude = etablissementData.coord.latitude;
            const longitude = etablissementData.coord.longitude;

            // Utilisez ces coordonnées dans votre état ou directement dans la carte
            setPosition([latitude, longitude]);
          }

          // Récupérer l'URL de l'image
          if (etablissementData.photoURL) {
            const storage = getStorage();
            const photoURL =
              typeof etablissementData.photoURL === "string"
                ? etablissementData.photoURL
                : etablissementData.photoURL.path; // Vérifier si c'est une référence Firestore

            const photoRef = ref(storage, photoURL);
            const photoURLResult = await getDownloadURL(photoRef);
            setEtablissement((prev) => ({
              ...prev,
              photoURL: photoURLResult, // Ajouter l'URL de l'image au state
            }));
          }

          // Récupération des avis associés à cet établissement
          const avisRef = collection(db, "avis");
          const q = query(avisRef, where("id_etablissement", "==", id));
          const avisSnapshot = await getDocs(q);

          let totalNote = 0;
          const avisList = [];
          const starsCounter = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };

          // Création d'un tableau de promesses pour récupérer les réponses des avis
          const reponsesPromises = avisSnapshot.docs.map(async (avisDoc) => {
            const avisData = avisDoc.data();
            avisData.id = avisDoc.id;
            avisData.date = avisData.date
              ? new Date(avisData.date.seconds * 1000)
              : null;

            // Récupérer les réponses des gérants
            const reponsesRef = collection(db, "avis", avisDoc.id, "reponses");
            const reponsesSnapshot = await getDocs(reponsesRef);
            const reponses = reponsesSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
              date: doc.data().date
                ? new Date(doc.data().date.seconds * 1000)
                : null,
            }));

            avisData.reponses = reponses; // Ajouter les réponses à l'avis
            avisList.push(avisData);
            totalNote += avisData.note;
            starsCounter[avisData.note] += 1;
          });

          // Attendre que toutes les réponses des avis soient récupérées en parallèle
          await Promise.all(reponsesPromises);
          avisList.sort((a, b) => b.date - a.date);
          setAvis(avisList);
          setFilteredAvis(avisList);
          setNombreAvis(avisList.length);
          setMoyenneNote(
            avisList.length ? (totalNote / avisList.length).toFixed(2) : 0
          );
          setStarsCount(starsCounter);
        } else {
          setError("Aucun établissement trouvé avec cet ID.");
        }
      } catch (error) {
        setError(
          "Erreur lors de la récupération des données : " + error.message
        );
      } finally {
        setLoading(false);
      }
    };
    fetchEtablissement();
  }, [id]);

  if (etablissement && !etablissement.valide) {
    return (
      <div className="text-center p-6 text-gray-600">
        Cet établissement n'est pas encore validé.
      </div>
    );
  }

  const handleFilterStars = (stars) => {
    setFilterStars(stars);
    setFilteredAvis(stars ? avis.filter((a) => a.note === stars) : avis);
    setVisibleAvisCount(10); // Reset pagination on filter change
  };

  const showMoreAvis = () => {
    setVisibleAvisCount((prev) => prev + 10);
  };

  const toggleExpandAvis = (index) => {
    setExpandedAvis((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  // Fonction pour gérer le changement de réponse
  const handleResponseChange = (avisId, response) => {
    setResponses((prev) => ({ ...prev, [avisId]: response }));
  };
  const handleResponseSubmit = async (avisId) => {
    if (!avisId || !responses[avisId]) {
      console.error("Avis ID invalide ou réponse vide", avisId);
      return;
    }

    const auth = getAuth(); // Récupère l'authentification Firebase
    const user = auth.currentUser; // Récupère l'utilisateur connecté

    if (!user) {
      console.error("Utilisateur non connecté !");
      alert("Vous devez être connecté pour répondre à un avis.");
      return;
    }
    try {
      const responseRef = collection(db, "avis", avisId, "reponses");
      const newResponse = {
        emailGerant: userEmail,
        texte: responses[avisId],
        date: new Date(),
      };
      const docRef = await addDoc(responseRef, newResponse);
      const savedResponse = { ...newResponse, id: docRef.id };

      // 🔹 Mise à jour immédiate des avis et des avis filtrés
      setAvis((prevAvis) =>
        prevAvis.map((a) =>
          a.id === avisId
            ? { ...a, reponses: [...(a.reponses || []), savedResponse] }
            : a
        )
      );

      setFilteredAvis((prevFiltered) =>
        prevFiltered.map((a) =>
          a.id === avisId
            ? { ...a, reponses: [...(a.reponses || []), savedResponse] }
            : a
        )
      );

      setResponses((prev) => ({ ...prev, [avisId]: "" }));
      setShowPopup(true);
    } catch (error) {
      console.error("Erreur d'enregistrement :", error);
    }
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  if (loading) return <div className="text-center p-6">Chargement...</div>;
  if (error) return <div className="text-center p-6 text-red-600">{error}</div>;

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg mt-12 mb-6">
      {etablissement && (
        <>
          {/* Affichage de l'image de l'établissement */}
          {etablissement.photoURL && (
            <div className="mb-6 flex justify-center items-center">
              <img
                src={etablissement.photoURL} // Utiliser l'URL récupérée
                alt={etablissement.nom}
                className="max-w-full max-h-[400px] w-auto h-auto object-contain rounded-lg"
              />
            </div>
          )}

          <h1 className="text-3xl font-bold text-center text-vert">
            {etablissement.nom}
          </h1>
          <p className="text-lg text-center text-gray-600 mt-2">
            {etablissement.ville}
          </p>
          <p className="text-md text-center text-gray-500 mt-1">
            {etablissement.adresse}
          </p>
          {/* Affichage du lien de réservation si disponible */}
          {etablissement.reservation && (
            <div className="text-center mt-6">
              <a
                href={etablissement.reservation}
                target="_blank"
                rel="noopener noreferrer"
                className="px-6 py-3 bg-bleu text-white font-semibold rounded-lg hover:bg-vert transition"
              >
                Réservez une table
              </a>
            </div>
          )}
          {etablissement.telephone && (
            <div className="text-center mt-6">
              <a
                href={`tel:${etablissement.telephone}`}
                className="text-bleu font-medium hover:underline flex items-center justify-center"
              >
                <PhoneIcon className="h-6 w-6 mr-2 text-bleu" />
                {etablissement.telephone}
              </a>
            </div>
          )}
          {etablissement.pageFacebook && (
            <div className="text-center mt-2">
              <a
                href={etablissement.pageFacebook}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 font-medium hover:underline"
              >
                Visiter la page Facebook
              </a>
            </div>
          )}

          {etablissement.siteInternet && (
            <div className="text-center mt-2">
              <a
                href={etablissement.siteInternet}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 font-medium hover:underline"
              >
                Visiter le site Internet
              </a>
            </div>
          )}

          <div
            onClick={toggleMapVisibility}
            className="flex items-center mb-4 justify-center cursor-pointer text-bleu hover:text-vert transition text-lg font-semibold space-x-2 mt-4"
          >
            {showMap ? (
              <>
                <span className="transform rotate-90 transition-all duration-200">
                  📍
                </span>
                <span>Cacher l'emplacement</span>
              </>
            ) : (
              <>
                <span className="transition-all duration-200">📍</span>
                <span>Voir où nous sommes l</span>
              </>
            )}
          </div>

          {/* Affichage de la carte */}
          {showMap && (
            <Map
              position={position}
              adresse={etablissement.adresse}
              ville={etablissement.ville}
            />
          )}

          <div className="mt-6">
            <h2 className="text-2xl font-semibold text-bleu">
              Avis (Moyenne: {moyenneNote})
            </h2>
            <p className="text-gray-500 text-center">{nombreAvis} avis</p>

            <div className="flex justify-center space-x-4 mt-4">
              {[5, 4, 3, 2, 1].map((stars) => (
                <button
                  key={stars}
                  onClick={() => handleFilterStars(stars)}
                  className={`px-4 py-2 rounded-full ${
                    filterStars === stars
                      ? "bg-vert text-white"
                      : "bg-gray-200 text-gray-700"
                  } hover:bg-vert hover:text-white transition`}
                >
                  {stars} ⭐ ({starsCount[stars] || 0})
                </button>
              ))}
              <button
                onClick={() => handleFilterStars(null)}
                className={`px-4 py-2 rounded-full ${
                  filterStars === null
                    ? "bg-bleu text-white"
                    : "bg-gray-200 text-gray-700"
                } hover:bg-bleu hover:text-white transition`}
              >
                Tous ({nombreAvis})
              </button>
            </div>

            {/* Affichage des avis */}
            {filteredAvis.length > 0 && (
              <div className="mt-6 space-y-4">
                {filteredAvis.slice(0, visibleAvisCount).map((avis, index) => (
                  <div
                    key={avis.id || `avis-${index}`}
                    className="bg-vert bg-opacity-10 p-4 rounded-lg shadow-md border-l-4 border-vert"
                  >
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-bleu font-bold">{avis.auteur}</span>
                      <span className="text-vert font-semibold">
                        {avis.note}/5 ⭐
                      </span>
                    </div>
                    <p className="text-gray-500 text-sm">
                      {avis.date
                        ? avis.date.toLocaleDateString()
                        : "Date inconnue"}
                    </p>
                    <p className="text-gray-700">
                      {expandedAvis[index] || avis.commentaire.length <= 100
                        ? avis.commentaire
                        : `${avis.commentaire.slice(0, 100)}...`}
                      {avis.commentaire.length > 100 && (
                        <button
                          onClick={() => toggleExpandAvis(index)}
                          className="text-vert font-semibold ml-2"
                        >
                          {expandedAvis[index] ? "Voir moins" : "Voir plus"}
                        </button>
                      )}
                    </p>
                    {/* Affichage des réponses du gérant */}
                    {avis.reponses && avis.reponses.length > 0 && (
                      <div className="mt-4 bg-gray-100 p-3 rounded-md">
                        <h3 className="font-semibold text-bleu">
                          Réponse du gérant :
                        </h3>
                        {avis.reponses.map((reponse) => (
                          <div
                            key={reponse.id}
                            className="mt-2 p-2 bg-white rounded-md shadow"
                          >
                            <p className="text-sm text-gray-600">
                              {reponse.date?.toLocaleDateString()}
                            </p>
                            <p className="text-gray-800">{reponse.texte}</p>
                          </div>
                        ))}
                      </div>
                    )}

                    {/* Si le gérant est connecté et que c'est son établissement */}
                    {userEmail === etablissement.email && (
                      <div className="mt-4">
                        <button
                          onClick={() =>
                            setShowResponseInput((prev) => ({
                              ...prev,
                              [avis.id]: !prev[avis.id],
                            }))
                          }
                          className="px-4 py-2 bg-bleu text-white rounded-md hover:bg-vert transition"
                        >
                          Répondre
                        </button>

                        {/* Zone de réponse cachée */}
                        {showResponseInput[avis.id] && (
                          <>
                            <textarea
                              value={responses[avis.id] || ""}
                              onChange={(e) =>
                                handleResponseChange(avis.id, e.target.value)
                              }
                              placeholder="Répondre à cet avis..."
                              className="w-full p-2 border rounded-md mt-2"
                            />
                            <button
                              onClick={() => handleResponseSubmit(avis.id)}
                              className="mt-2 px-4 py-2 bg-bleu text-white rounded-md hover:bg-vert transition"
                            >
                              Envoyer la réponse
                            </button>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                ))}
                {visibleAvisCount < filteredAvis.length && (
                  <button
                    onClick={showMoreAvis}
                    className="mt-4 w-full bg-vert text-white py-2 rounded-lg hover:bg-bleu transition"
                  >
                    Afficher plus
                  </button>
                )}
              </div>
            )}
          </div>
        </>
      )}
      {/* Popup de confirmation */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-xl font-bold text-vert">Réponse envoyée !</h2>
            <p className="text-gray-600 mt-2">
              Votre réponse a été enregistrée avec succès.
            </p>
            <button
              onClick={handleClosePopup}
              className="mt-4 px-4 py-2 bg-bleu text-white rounded-md hover:bg-vert transition"
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EtablissementDetailPage;
